import { css, StyleSheet } from 'aphrodite'
import React, { useEffect, useState } from 'react'
import { fadeOut, pulse } from 'react-animations'
import fadeIn from 'react-animations/lib/fade-in'
import { isAndroid, isDesktop, isIOS } from 'react-device-detect'
import { sendEvent } from '../../../google-analytics'
import { useQR } from '../../../stores'
import ButtonImgDark from './textures/ar-dark.png'
import ButtonImg from './textures/AR.svg'
// import animationData from './Room'
// import QRCode from "react-qr-code";

/*
Change only the below two
*/
const getGlb = (name) => {
  switch (name) {
    case 'ballot':
      return 'ballot_box.glb'
    case 'refrigerator':
      return 'Refrigerator_10.glb'
    case 'navtal':
      return 'NAVTAL_02.glb'
    case 'squarelock':
      return 'Lock_10.glb'
    case 'typewriter':
      return 'Typewriter_06.glb'
  }
}
const getUsdz = (name) => {
  switch (name) {
    case 'ballot':
      return 'ballot_box.usdz'
    case 'refrigerator':
      return 'refrigerator.usdz'
    case 'navtal':
      return 'navtal.usdz'
    case 'squarelock':
      return 'squarelock.usdz'
    case 'typewriter':
      return 'typewriter.usdz'
  }
}
export default function AR(props) {
  const baseurl = `https://aws.autovrse.in/staging/godrej/archives/Models/`
  const AndroidURL = props.androidURL
  //   "https://aws.autovrse.in/godrej-lock/_webar/AdvantisLock.glb";
  // const AndroidURL = `./Models/ballot_box.glb`
  const IOSIntent = props.appleUrl
  //   "https://aws.autovrse.in/godrej-lock/_webar/AdvantisLock.usdz";
  const qr = useQR()
  const AndroidIntent =
    'intent://arvr.google.com/scene-viewer/1.0?file=' +
    AndroidURL +
    '#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;'
  const [QR, setQR] = useState(false)

  function OnRequestAR() {
    sendEvent(`Android AR clicked`, 'AR Android', 'AR')
    window.open(AndroidIntent)
  }
  function OnRequestARDesktop() {
    // console.log('setting')
    sendEvent(`Desktop AR clicked`, 'AR Desktop', 'QR')
    qr.setQR(
      `https://master.dy2v8xoym67fn.amplifyapp.com/?android=${AndroidURL}&ios=${IOSIntent}`
    )
  }
  const defaultOptions = {
    // animationData:animationData,
    autoplay: false,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    loop: false,
  }
  return (
    <div className={css(styles.container)} {...props}>
      {isAndroid && (
        <button onClick={OnRequestAR} className={css(styles.button)}>
          <img alt="Start AR" className={css(styles.image)} src={ButtonImg} />
        </button>
      )}
      {isIOS && (
        <a rel="ar" href={IOSIntent}>
          <img
            alt="Start AR"
            src={ButtonImgDark}
            className={css(styles.image)}
          />
        </a>
      )}
      {isDesktop && (
        <>
          <button onClick={OnRequestARDesktop} className={css(styles.button)}>
            <img alt="Start AR" className={css(styles.image)} src={ButtonImg} />
          </button>
        </>
      )}
    </div>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 20,
    // width:40,
    // height:40,
    // backgroundColor:'red',
    // left: 10,
    // height: 45,
    // textAlign: "center",
    zIndex: 120,
    '@media (max-width: 1100px)': {
      transform: 'scale(0.7)',
    },
  },
  button: {
    outline: 'none',
    background: 'none',
    // pointerEvents:'auto',
    cursor: 'pointer',
    border: 'none',
    ':active': {
      transform: 'scale(0.8)',
    },
    // animationName: pulse,
    animationDuration: '1s, 1200ms',
    animationIterationCount: 'infinite',
    animationFillMode: 'forwards',
    ':hover': {
      transform: 'scale(1.1)',
    },
  },
  image: {
    height: '5.7vh',
    // animationName: pulse,
    animationDuration: '1s, 1200ms',
    animationIterationCount: 'infinite',
    animationFillMode: 'forwards',
  },
  qrcontainer: {
    animationName: fadeIn,
    animationDuration: '1s, 1200ms',
    animationIterationCount: 'once',
    animationFillMode: 'forwards',
  },
  qr: {
    height: 150,
  },
  qrButton: {
    outline: 'none',
    background: 'none',
    border: 'none',
  },
})
