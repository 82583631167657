import axios from 'axios'
import create from 'zustand'

// export const baseUrl = 'http://localhost:5000'
// export const baseUrl = process.env.NODE_ENV==="development"?"http://localhost:5000": 'https://glafsarviewer.autovrse.app'
// export const baseUrl = process.env.NODE_ENV==="development"?"https://glafsarviewer.autovrse.app": 'https://glafsarviewer.autovrse.app'
export const baseUrl =
  process.env.NODE_ENV === 'development'
    ? 'https://api.virtualexperience.godrej.com'
    : 'https://api.virtualexperience.godrej.com'

export const getImageUrl = async (code) => {
  let imageUrl = baseUrl + '/thumbnail/' + code
  return imageUrl
}
export const thumbnailUrl = baseUrl + '/thumbnail/'
export const useModal = create((set) => ({
  show: false,
  data: {},
  setShow: (pos) => set((state) => (state.show = pos)),
  setData: (data) => set((state) => (state.data = data)),
}))
