import { animated as a, useSpring } from '@react-spring/web'
import { css, StyleSheet } from 'aphrodite'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useCylinder, useNewLoadingScreen, usePage } from '../../stores'
import LoadingBar from './LoadingBar'
// import { StyleSheet, css } from 'aphrodite';
import './NewLoadingScreen.css'
const images = {
  0: ['./asd/img1', './asd/img2'],
  1: ['./zxc/img1', './zxc/img2'],
  2: [],
}
export const NewLoadingScreen = () => {
  const pageState = usePage()
  const [textIdx, setIdx] = useState(0)
  const { room } = useCylinder()
  const { page } = usePage()
  const loading = useNewLoadingScreen()
  useEffect(() => {
    // console.log('new loader',loading.isLoading)
    if (!loading.isLoading) loading.setProgress(0)
  }, [loading.isLoading])
  const spring = useSpring({
    from: { opacity: 1 },
    opacity: loading.isLoading || loading.isSkyboxLoading ? 1 : 0,
    duration: loading.isLoading || loading.isSkyboxLoading ? 0 : 150,
  })
  const images = {
    0: [
      'Amando/AmandoLS_1.webp',
      'Amando/AmandoLS_2.webp',
      'Amando/AmandoLS_3.webp',
      'Amando/AmandoLS_4.webp',
    ],
    1: [
      'Lobby/LobbyLS_1.webp',
      'Lobby/LobbyLS_2.webp',
      'Lobby/LobbyLS_3.webp',
      'Lobby/LobbyLS_4.webp',
    ],
    2: [
      'Living Room/LivRoomLS_1.webp',
      'Living Room/LivRoomLS_2.webp',
      'Living Room/LivRoomLS_3.webp',
      'Living Room/LivRoomLS_4.webp',
    ],
    3: [
      'Door Handles/PopMortLS_1.webp',
      'Door Handles/PopMortLS_2.webp',
      'Door Handles/PopMortLS_3.webp',
      'Door Handles/PopMortLS_4.webp',
    ],
    4: [
      'Study/StudyLS_1.webp',
      'Study/StudyLS_2.webp',
      'Study/StudyLS_3.webp',
      'Study/StudyLS_4.webp',
    ],
    cylinder: [
      'Study/StudyLS_3.webp',
      'Door Handles/PopMortLS_2.webp',
      'Living Room/LivRoomLS_2.webp',
      'Amando/AmandoLS_3.webp',
    ],
  }
  const text = {
    0: [
      'Amando is an international range of premium door handles for every home.',
      'Exquisitely crafted and engineered,Amando brings a heightened aesthetic experience to your doors',
      'Very contemporary,Amando is a treat to you give your senses,daily.',
      'Amando is an international range of premium door handles for every home.',
    ],
    1: [
      'Godrej Locks presents a range of digital locking solutions which are the very cutting-edge of home safety. ',
      'Incorporating the latest safety features from biometric access, RFID cards, spy code entry and many more. ',
      'These digital locks enable Indian homes and offices to be protected by the very best of safety science. ',
      'These digital locks enable Indian homes and offices to be protected by the very best of safety science. ',
    ],
    2: [
      'The ‘safety guardians’ of the nation, Godrej Locks continues to be the leader in the market and in the minds of indians. ',
      'Incorporating the latest safety features from biometric access, RFID cards, spy code entry and many more. ',
      'These digital locks enable Indian homes and offices to be protected by the very best of safety science. ',
      'These digital locks enable Indian homes and offices to be protected by the very best of safety science. ',
    ],
    3: [
      'The ‘safety guardians’ of the nation, Godrej Locks continues to be the leader in the market and in the minds of indians. ',
      'The ‘safety guardians’ of the nation, Godrej Locks continues to be the leader in the market and in the minds of indians. ',
      'The ‘safety guardians’ of the nation, Godrej Locks continues to be the leader in the market and in the minds of indians. ',
      'The ‘safety guardians’ of the nation, Godrej Locks continues to be the leader in the market and in the minds of indians. ',
    ],
    4: [
      'The ‘safety guardians’ of the nation, Godrej Locks continues to be the leader in the market and in the minds of indians. ',
      'The ‘safety guardians’ of the nation, Godrej Locks continues to be the leader in the market and in the minds of indians. ',
      'The ‘safety guardians’ of the nation, Godrej Locks continues to be the leader in the market and in the minds of indians. ',
      'The ‘safety guardians’ of the nation, Godrej Locks continues to be the leader in the market and in the minds of indians. ',
    ],
  }
  const roomNames = {
    0: 'Amando',
    1: 'Lobby',
    2: 'Living Room',
    3: 'Door Handles',
    4: 'Study',
  }
  const location = useLocation()

  useEffect(() => {
    console.log('room just changed') // will have the current room we are in
    // 0 would mean we are in kitech 1 - amando 2 -lobby
    setIdx(0)
  }, [page, room])
  const timeout = useRef()
  useEffect(() => {
    timeout.current = setTimeout(() => {
      setIdx(textIdx < 3 ? textIdx + 1 : 0)
    }, [3000])
    return () => clearTimeout(timeout.current)
  }, [textIdx])

  return (
    <>
      <a.div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          zIndex: 120,
          pointerEvents: 'none',
          backgroundColor: 'black',
          ...spring,
          // opacity:0.5
        }}
      >
        {room > 0 && room <= 5 && (
          <div className={css(styles.text)}>
            <h1 className={css(styles.h)}>{roomNames[room - 1]}</h1>
            <p className={css(styles.p)}>{text[room - 1][textIdx]}</p>
          </div>
        )}
        {room === 0 && (
          <div className={css(styles.text)}>
            <p className={css(styles.p)}>Loading</p>
          </div>
        )}
        {/* {room!=0 && page==='showcase' &&  
          <>
            <div class="fadecycle">
              <img src={images[room-1][0]} />
              <img src={images[room-1][1]} />
              <img src={images[room-1][2]} />
              <img src={images[room-1][3]} />
            </div>
          </>} */}
        {page !== 'showcase' && (
          <div class="fadecycle">
            <img src={images.cylinder[0]} />
            <img src={images.cylinder[1]} />
            <img src={images.cylinder[2]} />
            <img src={images.cylinder[3]} />
          </div>
        )}
        <div
          style={{ opacity: room === 1 && page === 'showcase' ? 1 : 0 }}
          class={room === 1 && page === 'showcase' ? 'fadecycle' : ''}
        >
          <img src={images[0][0]} />
          <img src={images[0][1]} />
          <img src={images[0][2]} />
          <img src={images[0][3]} />
        </div>
        <div
          style={{ opacity: room === 2 && page === 'showcase' ? 1 : 0 }}
          class={room === 2 && page === 'showcase' ? 'fadecycle' : ''}
        >
          <img src={images[1][0]} />
          <img src={images[1][1]} />
          <img src={images[1][2]} />
          <img src={images[1][3]} />
        </div>
        <div
          style={{ opacity: room === 3 && page === 'showcase' ? 1 : 0 }}
          class={room === 3 && page === 'showcase' ? 'fadecycle' : ''}
        >
          <img src={images[2][0]} />
          <img src={images[2][1]} />
          <img src={images[2][2]} />
          <img src={images[2][3]} />
        </div>
        <div
          style={{ opacity: room === 4 && page === 'showcase' ? 1 : 0 }}
          class={room === 4 && page === 'showcase' ? 'fadecycle' : ''}
        >
          <img src={images[3][0]} />
          <img src={images[3][1]} />
          <img src={images[3][2]} />
          <img src={images[3][3]} />
        </div>
        <div
          style={{ opacity: room === 5 && page === 'showcase' ? 1 : 0 }}
          class={room === 5 && page === 'showcase' ? 'fadecycle' : ''}
        >
          <img src={images[4][0]} />
          <img src={images[4][1]} />
          <img src={images[4][2]} />
          <img src={images[4][3]} />
        </div>
        <div
          style={{ opacity: room === 5 && page === 'showcase' ? 1 : 0 }}
          class={room === 5 && page === 'showcase' ? 'fadecycle' : ''}
        >
          <img src={images[4][0]} />
          <img src={images[4][1]} />
          <img src={images[4][2]} />
          <img src={images[4][3]} />
        </div>
        <LoadingBar amount={loading.progress} />
      </a.div>
    </>
  )
}

const styles = StyleSheet.create({
  text: {
    position: 'absolute',
    bottom: '3.5vh',
    left: '1%',
    fontFamily: 'Work Sans',
    fontWeight: 400,
    color: 'white',
    zIndex: 120,
  },
  h: {
    // fontSize:64,
    fontSize: '6.5vh',
    margin: 0,
  },
  p: {
    fontSize: '3.2vh',
    marginTop: '1.1vh',
  },
})
