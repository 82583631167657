import { config, useSpring } from '@react-spring/core'
import { useGLTF, useAnimations } from '@react-three/drei'
import React, { useEffect, useRef, useState } from 'react'
import {
  useLocalLoader,
  usePage,
  useRoom,
  usePopup,
  useCylinder,
  useNewLoadingScreen,
} from '../../../stores'
import { HotspotManger } from './hotspots/HotspotManger'
import { animated as a } from '@react-spring/three'
import { GlbParser } from '../../Misc/GlbParser'
import { springConfigs } from '../../../appStyles'
import * as THREE from 'three'
import { useControls } from 'leva'
import { RoomAnimations } from './RoomAnimations'

function playAnimationForward(anim) {
  if (!anim) return
  anim.loop = THREE.LoopOnce
  anim.timeScale = 1
  anim.clampWhenFinished = true
  anim.paused = false
  anim.play()
}

function playAnimationBackward(anim) {
  if (!anim) return
  anim.loop = THREE.LoopOnce
  anim.clampWhenFinished = true
  anim.timeScale = -1
  anim.paused = false
  anim.play()
}

export const Room = ({ setShow, show, url, ...props }) => {
  const popup = usePopup()
  const room = useRoom()
  const mesh = useRef()
  const loader = useLocalLoader()
  const pageState = usePage()
  const model = useGLTF(
    url,
    'https://www.gstatic.com/draco/versioned/decoders/1.5.0/'
  )
  const cylinder = useCylinder()
  const { toggle } = useControls({ toggle: { value: false } })
  const modelAnimation = useAnimations(model.animations, model.scene)
  const newLoader = useNewLoadingScreen()
  useEffect(() => {
    setTimeout(() => newLoader.setIsLoading(false), [200])

    if (url === '/environment/Fin_AmandoDisplay1.glb') {
      // model.position.setY(model.scene.position.y)
    }
    // let cache = new Cache()
    // console.log('cache', window.Cache.keys())
    !(url in loader.cache) && loader.appendToCache(url)
  }, [])
  const spring = useSpring({
    from: { y: props.enter ? 20 : 0 },
    y: props.enter ? 0 : 20,
  })
  // useEffect(()=>{
  //   if (toggle )playAnimationForward(modelAnimation.actions.Drawer1Action)
  //   if (!toggle )playAnimationBackward(modelAnimation.actions.Drawer1Action)
  //   if(toggle )playAnimationBackward(modelAnimation.actions.Drawer2Action)
  //   if (!toggle )playAnimationForward(modelAnimation.actions.Drawer2Action)
  // },[toggle])
  // useEffect(()=>{
  //   if( modelAnimation.actions && Object.keys(modelAnimation).length){
  //     if(toggle){
  //       Object.values(modelAnimation.actions).map(anim => {
  //         playAnimationForward(anim)
  //       })
  //     } else {
  //       Object.values(modelAnimation.actions).map(anim => {
  //         playAnimationBackward(anim)
  //       })
  //     }

  //   }
  // },[toggle])
  // useEffect(() => {
  //   let pid = popup.data.popupId
  //   if (cylinder != null && cylinder.room != null) {
  //     switch (cylinder.room) {
  //       case 0:
  //         break
  //       case 5:
  //         break
  //       default:
  //         break

  //     }
  //   }
  // }, [popup, cylinder])
  // useEffect(()=>{
  //   // loader.setLoading(100)
  // },[])
  // const [urlInt,setUrl] = useState()
  // useEffect(()=>{
  // const [show, setShow] = useState(true);
  // },[room.url])

  // useEffect(() => {
  //   pageState.page === "showcase" && setShow(true);
  // }, [pageState]);
  // // //console.log(room.url)

  // const spring = useSpring({
  //   from: { zRotate: -Math.PI },
  //   pause:loader.progress!==100,
  //   // opacity: pageState.page === "showcase" ? 1 : 0,
  //   zRotate: pageState.page === "showcase" ? 0 : -Math.PI,
  //   config: springConfigs.slower,
  // });

  // const { opacity } = useSpring({
  //   from: { opacity: 0 },
  //   pause:loader.progress!==100,
  //   opacity: pageState.page === "showcase" ? 1 : 0,
  //   onRest: () => pageState.page !== "showcase" && setShow(false),
  //   onStart: () => setShow(true),
  //   config: springConfigs.evenSlower,
  // });

  // const { fastOpacity } = useSpring({
  //   from: { fastOpacity: 0 },
  //   pause:loader.progress!==100,
  //   fastOpacity: pageState.page === "showcase" ? 1 : 0,
  //   config: springConfigs.slower,
  // });
  // let springs = { ...spring, opacity, fastOpacity };
  return (
    <>
      <a.group position={cylinder.room == 1 ? [0, 0.5, 0] : [0, 0, 0]}>
        {/* <a.group 
          // position={[0,props.spring.to(x=>x),0]}
          // position-y={spring.y}
        > */}
        <a.primitive
          object={model.scene}
          // position={[0,-0.8,0]}
          // rotation={[0, Math.PI / 2, 0]}
        />
        {/* <GlbParser spring={springs} object={model} /> */}
      </a.group>
      <HotspotManger
        hotspots={room.data.hotspots?.map((x, i) => ({ ...x, id: i }))}
      />
      <RoomAnimations animations={modelAnimation.actions} />
    </>
  )
}
