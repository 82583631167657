import React, { useEffect, useRef } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Vector3 } from 'three'
import { cameraLocations, rooms } from '../../constants'
import {
  useCameraStore,
  useCylinder,
  useNewLoadingScreen,
  usePage,
  useParallax,
  usePopup,
  useProducts,
  useRoom,
  useSearch,
  useShouldMount,
} from '../../stores'

function getPage(loc) {
  // switch(loc) {
  //     case "cylinder":
  //         return 'cylinder'
  //     case "cylinderoutside":
  //         return 'cylinderoutside'
  //     case "showcase":
  //         return "showcase"
  //     case "catalog":
  //         return "catalog"

  //     default:
  //         return loc
  // }
  return loc
}
function getPopup(loc) {
  switch (loc) {
    case '':
      return ''
  }
}
export function getParams(searchStr) {
  let par = searchStr.slice(1, searchStr.length).split('&')
  let res = {}
  par.forEach((x) => (res[x.split('=')[0]] = x.split('=')[1]))
  Object.keys(res).forEach((x) => (res[x] = Number(res[x])))
  return res
}
const setCamera = (cameraState, { view, position, camera }) => {
  // //console.log('pop',popupInfo)
  let newPos = [...position]
  if (typeof camera === 'string') {
    let type = camera
    newPos[0] = newPos[0] + -newPos[0] / 5
    newPos[1] =
      newPos[1] +
      ((type === 'below'
        ? newPos[1] - 4
        : type === 'above'
        ? newPos[1] + 4
        : newPos[1]) -
        newPos[1]) /
        5
    newPos[2] = newPos[2] + -newPos[2] / 5

    if (view != null) {
      cameraState.setCamera(
        new Vector3(...view.position),
        new Vector3(...view.lookAt),
        view.fov || (cameraState.fov<42?cameraState.fov:35), // incase last cameraState is from default and not room 
        view.time 
      )
    } else
      cameraState.setCamera(
        new Vector3(...newPos),
        new Vector3(...position),
        50
      )
  } else {
    cameraState.setCamera(camera, new Vector3(...position), 50)
  }
}
export const RouteChecker = () => {
  const location = useLocation()
  const parallax = useParallax()
  const popup = usePopup()
  const history = useHistory()
  const pageState = usePage()
  const productState = useProducts()
  const roomState = useRoom()
  // //console.log(location)
  const cylinder = useCylinder()
  const newLoader = useNewLoadingScreen()
  const cameraState = useCameraStore()
  const shouldMount = useShouldMount()
  const {setCategory} = useSearch()
  useEffect(() => {
    let currentLocation = location.pathname.split('/')
    let params = getParams(location.search)

    // //console.log("changed",params)
    // //console.log("changed",location)

    pageState.setPage(getPage(currentLocation[1]))
  }, [location.pathname, location.search])
  useEffect(()=> {
  },[shouldMount])
  useEffect(() => {
    let params = getParams(location.search)
    if((pageState.page==='catalog' || pageState.page==='categories' || pageState.page==='mv') && pageState.lastPage===''){
      cameraState.setCamera(...Object.values(cameraLocations.cylinderoutside))
    }
    if(pageState.page === 'catalog'){
      setCategory(params.category)
    }
    if(pageState.page === 'about' && pageState.lastPage!=='showcase'){
      cameraState.setCamera(...Object.values(cameraLocations.cylinderoutside))
    }
    if (pageState.page === '') {
      cameraState.setCamera(...Object.values(cameraLocations.cylinderoutside))
      setTimeout(()=>{
        shouldMount.setCylinder(true) //setting cylinde to mount on when pagestatae is cylinder, unmounting only when showcase hence not needing to worry about hat later
        shouldMount.setShowcase(false)
      },[1000])
    }
    if (pageState.page === 'cylinder') {
      cameraState.setCamera(...Object.values(cameraLocations.cylinder))
      setTimeout(()=>{
        shouldMount.setCylinder(true) //setting cylinde to mount on when pagestatae is cylinder, unmounting only when showcase hence not needing to worry about hat later
        shouldMount.setShowcase(false)
      },[1000])
      cylinder.setRotate(true)
      cylinder.setWobble(true)
      cylinder.setRoom(params.room)
      cylinder.setInfo(rooms[params.room])
      
    } else {
      cylinder.setRotate(false)
      if (pageState.page !== 'cylinderoutside') cylinder.setWobble(false)
    }

    if (pageState.page === 'cylinderoutside') {
      // //console.log(...cameraLocations.cylinderoutside)
      cylinder.setRotate(false)
      cylinder.setWobble(true)
      cameraState.setCamera(...Object.values(cameraLocations.cylinderoutside))
    }
    
    parallax.setMove(false)
    
    if (pageState.page === 'showcase') {
      // parallax.setParallax(true)
      setTimeout(()=>{

        shouldMount.setCylinder(false) //setting cylinde to mount on when pagestatae is cylinder, unmounting only when showcase hence not needing to worry about hat later
        shouldMount.setShowcase(true)
      },[1000])
      let currentRoom = rooms[params.room]
      let currentLocation = currentRoom.locations[0]
      roomState.setUrl(currentRoom.url)
      roomState.setData(currentRoom)
      cylinder.setRoom(params.room)
      if (params.popup !== -1) {
        // popup.setData(currentRoom.hotspots[params.popup])
        //console.log(productState.products, currentRoom.hotspots[params.popup])

        popup.setData({
          popupId: params.popup,
          actions:currentRoom.hotspots[params.popup].actions,
          ...productState.products.find(
            (product) =>
              product.productCode ===
              currentRoom.hotspots[params.popup].productCode
          ),
        })
        setCamera(cameraState, currentRoom.hotspots[params.popup])
      } else {
        popup.setData({})
        setTimeout(
          () => cameraState.setCamera(...Object.values(currentLocation.camera)),
          50
        )
      }
    }

    // if(pageState.page === 'catalog')
  }, [pageState.page, location])
  const lastPage = useRef('')
  const cylinderPages = ['cylinder', '', '/']
  useEffect(() => {
    if(pageState.page === 'showcase' ){
      console.log('setting new loader from showcase')
      if(lastPage.current==='cylinder' || lastPage.current==='showcase')newLoader.setIsLoading(true)
    }else{
      if(lastPage.current === 'showcase' && cylinderPages.includes(pageState.page)){
        newLoader.setIsLoading(true)
      }
    }
    lastPage.current = pageState.page
  },[pageState.page, cylinder.room])
  return null
}
