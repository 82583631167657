import { config, useSpring } from '@react-spring/core'
import { animated } from '@react-spring/three'
import { Cylinder } from './Cylinder'
import React, { useState, useEffect } from 'react'
import { useDrag, useGesture, useMove } from 'react-use-gesture'
import { DoubleSide } from 'three'
import { BackDrop } from './BackDrop'
import {
  useCameraStore,
  useCylinder,
  usePage,
  useWorkaround,
} from '../../../stores'
import { isMobile } from 'react-device-detect'
function mod(n, m) {
  return ((n % m) + m) % m
}
const setRoom = (workaround, no) => {
  workaround.setUrl('/cylinder?room=' + mod(no, 6))
}
export const RotatingGroup = ({ children }) => {
  // const
  const cylinderState = useCylinder()
  const pageState = usePage()
  const workaround = useWorkaround()
  const [currentIdx, setCurrentIdx] = useState(cylinderState.room || 1)
  useEffect(() => {
    setCurrentIdx(cylinderState.room)
  }, [cylinderState.room])
  useEffect(() => {
    //console.log(pageState.page)
    if (pageState.page === 'cylinder') {
      setRoom(workaround, currentIdx)
    }
  }, [currentIdx])
  useEffect(() => {
    if (pageState.page === 'cylinder') {
      setCurrentIdx(cylinderState.room)
    }
  }, [pageState.page])
  // useEffect(()=>{
  //   if(currentIdx!==cylinderState.room)setCurrentIdx(cylinderState.room)
  // },[cylinderState.room])

  const degPerIdx = Math.PI / 3
  let factor = isMobile ? 2.5 : 2.5
  const [rotateSpring, api] = useSpring(() => ({
    rotation: [0, currentIdx * degPerIdx, 0],
  }))
  useEffect(() => {
    api.start({ rotation: [0, currentIdx * degPerIdx, 0] })
  }, [currentIdx])
  const binds = useGesture({
    onDrag: ({ down, active, velocity, movement: [x] }) => {
      let mx = x * factor
      if (currentIdx === 1 && mx < 0) return
      if (currentIdx === 5 && mx > 0) return
      if (
        pageState.page === 'cylinder' ||
        pageState.page === 'cylinderoutside'
      ) {
        api.start({
          rotation: [
            0,
            currentIdx * degPerIdx + ((mx / window.innerWidth) * Math.PI) / 3,
            0,
          ],
        })
        // //console.log(mx*1.3/window.innerWidth*Math.PI/3)
        if (!down) {
          if ((((mx * 1.3) / window.innerWidth) * Math.PI) / 3 > 1.25)
            setCurrentIdx((state) => state + 1)
          else if ((((mx * 1.3) / window.innerWidth) * Math.PI) / 3 > 0.6)
            setCurrentIdx((state) => state + 1)
          if ((((mx * 1.3) / window.innerWidth) * Math.PI) / 3 < -1.25)
            setCurrentIdx((state) => state - 1)
          else if ((((mx * 1.3) / window.innerWidth) * Math.PI) / 3 < -0.6)
            setCurrentIdx((state) => state - 1)
        }
      }
    },
    onDragEnd: ({ movement: [mx] }) =>
      api.start({ rotation: [0, currentIdx * degPerIdx, 0] }),
  })
  return (
    <animated.group {...binds()} {...rotateSpring}>
      {children}
    </animated.group>
  )
}
export const WobblyGroup = ({ children }) => {
  const maxDis = 0.03
  const cylinderState = useCylinder()
  const cameraState = useCameraStore()
  const pageState = usePage()
  const [moveSpring, moveApi] = useSpring(() => ({
    rotation: [0, 0, 0],
    config: config.stiff,
  }))
  const [dragSpring, dragApi] = useSpring(() => ({
    rotation: [0, 0, 0],
    config: config.stiff,
  }))
  const bind = useGesture({
    onMove: ({ velocity, movement: [mx, my] }) => {
      ;(pageState.page === 'cylinder' ||
        pageState.page === 'cylinderoutside') &&
        moveApi.start({
          rotation: [
            -0.02 + (maxDis * 1 * -my) / window.innerHeight,
            (1.5 * maxDis * 2 * mx) / window.innerWidth,
            0,
          ],
        })
    },
  })
  const drag = useDrag(
    ({ velocity, active, vxvy: [vx, vy], movement: [mx, my] }) => {
      ;(pageState.page === 'cylinder' ||
        pageState.page === 'cylinderoutside') &&
        dragApi.start({
          rotation: active
            ? [0, 0, (30 * (vx + vy)) / window.innerWidth]
            : [0, 0, 0],
        })
    }
  )
  return pageState.page === 'cylinder' ? (
    <animated.group {...bind()} {...moveSpring}>
      <animated.group {...drag()} {...dragSpring}>
        {children}
      </animated.group>
    </animated.group>
  ) : (
    <>{children}</>
  )
}
