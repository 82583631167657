import React from 'react'
import { ArrowLeft, ArrowRight, ShowcaseArrowLeft, ShowcaseArrowRight } from '../../../assets/svgs/Arrows'
import { css,StyleSheet } from 'aphrodite'
import { useSpring } from '@react-spring/web'
import { animated as a  } from '@react-spring/web'
import { useHistory } from 'react-router-dom'
import { useCylinder } from '../../../../stores'
export const CylinderLeftRight = ({shouldShow}) => {
    const history = useHistory()
    const {room} = useCylinder()
    const spring = useSpring({
        opacity:shouldShow?1:0
    })
  return (
    <div >
        <a.div  style={{...spring, pointerEvents:shouldShow?'auto':'none'}} className={css(styles.left)}>
            <ShowcaseArrowLeft notransform onClick={() => history.push(`/cylinder?room=${room===1?5:room-1}`)}/>

        </a.div>
        <a.div style={{...spring, pointerEvents:shouldShow?'auto':'none'}} className={css(styles.right)}>
            <ShowcaseArrowRight notransform onClick={() => history.push(`/cylinder?room=${room===5?1:room+1}`)}/>

        </a.div>
    </div>
  )
}

const styles = StyleSheet.create({
    left:{
        position:'absolute',
        width:'4vh',
        right:'52.6vw',
        bottom:'10%',
        cursor:'pointer'

    },
    right:{
        position:'absolute',
        width:'4vh',
        left:'52.6vw',
        cursor:'pointer',
        bottom:'10%',
    },
})