import React, { useEffect, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import axios from 'axios'
import { commonStyles } from '../commonStyles'
import { Modal } from './Modal'
import { baseUrl, getImageUrl, thumbnailUrl, useModal } from '../cmsstore'

const Item = (props) => {
    const modal = useModal()
    const [showImg,setShow] = useState(false)
    const [image,setImage] = useState(false)
    const handleClick = async () => {
       modal.setShow(true)
       modal.setData({...props})
    }
    useEffect(()=>{
        setImage(getImageUrl(props.productCode))
    },[])
    
    return (
        <>
        <div onMouseLeave={()=>setShow(false)} onMouseEnter={()=>setShow(true)} onClick={handleClick} className={css(itemStyles.item)}>
            
            {/* <p >{props.idx+1}.</p> */}
            <div className={css(itemStyles.blocks,commonStyles.paragraph)}>
                <p>{props.idx+1}.</p>
            </div>
            <div className={css(itemStyles.blocks,commonStyles.paragraph)}>
                <p>{props.productCode}</p>
            </div>
            <div className={css(itemStyles.blocks,commonStyles.paragraph)}>
                {showImg && <div className={css(styles.hover)}>
                    {/* <img width='400px' src={`data:image/png;base64,${Buffer.from(props.thumbnail.data.data).toString('base64')}`}/> */}
                    <img height='400px' width='400px' src={thumbnailUrl+(props.productCode)} alt=""/>
                </div>}
                <p style={{width:"100%",marginRight:'5%'}}>{props.name}</p>
            </div>
            <div className={css(itemStyles.blocks,commonStyles.paragraph)}>
                <p>{props.updatedAt}</p>
            </div>
            {/* {showModal && <Modal id={props._id}/>} */}
        </div>
            {/* <div style={{position:'absolute',marginTop:'-2.2%',left:'0.5%'}} className={css(itemStyles.blocks,commonStyles.paragraph)}>
                <a onClick={handleEdit}>edit</a>
            </div> */}
        <div>

        </div>
        </>
    )
}
const itemStyles = StyleSheet.create({
    item:{
        display:'flex',
        justifyContent:'flex-start',
        width:'80%',
        // margin:'1%',
        // borderRadius:'9px',
        boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        cursor:'pointer',
        ':hover':{
            boxShadow:'0 4px 8px 0 rgba(110, 110, 110, 0.2), 0 6px 20px 0 rgba(110, 110, 110, 0.19)'
        },
        paddingLeft:'2%',
        paddingRight:'2%',
        overflowX:'visible'
    },
    blocks:{
        // backgroundColor:'green',
        margin:'0% 3.5% 0 0'
        
    }
})


export const ItemsContainer = ({data,setData,setEditing}) => {
    // const [data,setData] = useState([])
    
    return (
        <div className={css(styles.container)}>
            {
                data.map((x,i)=><Item idx={i} key={i} {...x}/>)
            }
        </div>
    )
}

const styles = StyleSheet.create({
    container:{
        width:'90%',
        height:'80%',
        margin:'auto',
        overflowY:'scroll',
        overflowX:'visible'
        // marginTop:'4%',
        // backgroundColor:'blue'
        
    },
    hover:{
        position:'absolute',
        top:0,
        pointerEvents:'none',
        // backgroundColor:'red'
        // right:0
    }
})