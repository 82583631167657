import React, { Suspense, useEffect, useState } from "react";
import { css, StyleSheet } from "aphrodite";
import { ItemsContainer } from "./components/ItemsContainer";
import { commonStyles } from "./commonStyles";
import { Modal } from "./components/Modal";
import { Create } from "./components/Create";
import axios from "axios";
import { baseUrl } from "./cmsstore";
import { Html, useProgress } from "@react-three/drei";
import { CmsLoading } from "./components/CmsLoading";
import { QRModal } from "../components/core/QRModal";
import { useQR } from "../stores";
import AuthUserUI from "./components/AuthUserUI";

const filteredData = ({ key, value }, data) => {
  if (!key) {
    return data;
  } else {
    return data.filter((item) =>
      item.hasOwnProperty(key)
        ? item[key].toLowerCase().slice(0, value.length) === value.toLowerCase()
          ? true
          : false
        : false
    );
  }
};
export const Cms = (props) => {
  const [data, setData] = useState([]);
  const [changed, setChanged] = useState(true);
  const [loading, setLoading] = useState(0);
  const qr = useQR();
  const [skyboxes, setSkyboxes] = useState([]);
  const [search, setSearch] = useState({ key: "", value: "" });
  const [validated, setValidated] = useState(false);
  const toggleChanged = () => {
    setChanged((state) => !state);
  };
  useEffect(() => {}, [search]);
  
  useEffect(() => {
    const fetch = async () => {
      try {
        let response = await axios.get(baseUrl);
        response.data = response.data
          .map((x) => ({
            ...x,
            updatedAt: x.updatedAt.replace(/T/, " ").replace(/\..+/, ""),
          }))
          .sort((a, b) => (a.name > b.name ? 1 : -1));
        setData(response.data);
      } catch (err) {
        console.error(err);
      }
    };
    const fetchHdri = async () => {
      try {
        let response = await axios.get(baseUrl + "/skybox");
        setSkyboxes(response.data.map((x) => x.name));
      } catch (err) {}
    };
    fetch();
    fetchHdri();
  }, [changed]);
  return validated ? (
    <>
      {/* <Suspense fallback={<Loader />}> */}
      {/* </Suspense> */}
      <Modal
        skyboxes={skyboxes}
        setLoading={setLoading}
        toggleChanged={toggleChanged}
        data={data}
      />
      
      {qr.url && <QRModal />}
      <CmsLoading loading={loading} />
      <div className={css(styles.container)}>
        <div className={css(styles.flex)}>
          <div className={css(styles.items)}>
            <div className={css(styles.heading)}>
              <h1 className={css(commonStyles.heading)}>Godrej-Lafs</h1>
              <h5 style={{ marginLeft: "1%" }}>
                Search
                (keys:collectionName,finish,name,productCode,category)case
                sensitive keys
              </h5>
            </div>
            <form className={css(styles.form)}>
              <label className={css(styles.label)}>key:</label>
              <input
                value={search.key}
                onChange={(e) =>
                  setSearch((state) => ({ ...state, key: e.target.value }))
                }
                className={css(styles.input)}
              />
              <label className={css(styles.label)}>value:</label>
              <input
                value={search.value}
                onChange={(e) =>
                  setSearch((state) => ({ ...state, value: e.target.value }))
                }
                className={css(styles.input)}
              />
            </form>
            <ItemsContainer
              setLoading={setLoading}
              changed={changed}
              data={filteredData(search, data)}
              setData={setData}
            />
          </div>
          <div style={{ width: "35%" }}>
            <button className={css(styles.logout)} 
              onClick={()=>{
                localStorage.clear()
                window.location.reload()
              }}>Logout</button>
            <Create
              skyboxes={skyboxes}
              setLoading={setLoading}
              toggleChanged={toggleChanged}
              data={data}
            />
          </div>
        </div>
      </div>
    </>
  ) : (
    <AuthUserUI setValidated={setValidated}/>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    overflow: "hidden",
    // paddingTop:'10vh',
    // paddingBottom:'10vh',
    // overflow:'hidden',
    // backgroundColor:'red'
  },
  heading: {
    textAlign: "center",
    margin: "4%",
  },
  items: {
    width: "65%",
    "@media (max-width: 900px)": {
      width: "100%",
    },
  },
  flex: {
    position: "absolute",
    left: 0,
    top: 0,
    display: "flex",
    width: "100%",
    height: "100%",
    oveflow: "hidden",
    "@media (max-width: 900px)": {
      display: "block",
      overflow: "auto",
    },
  },

  form: {
    display: "flex",
    width: "100%",
    textAlign: "center",
    marginLeft: "50vw",
    transform: "translateX(-50%)",
    marginTop: "-2%",
    marginBottom: "2%",
  },
  label: {
    display: "block",
    fontSize: "1.2rem",
    marginTop: "1%",
  },
  input: {
    display: "block",
    fontSize: "1rem",
    width: "20%",
    backgroundColor: 'white'
  },
  logout:{
    position:'absolute',
    right: 0,
    margin: 10,
    fontSize: 16,
    padding: 5,
    zIndex:20000
  }
});
