import { useSpring } from '@react-spring/core'
import { css, StyleSheet } from 'aphrodite'
import React, { useEffect, useState } from 'react'
import { PopupScreen } from '../popup/PopupScreen'
import {
  useCustomFade,
  useCylinder,
  usePage,
  usePopup,
  useWorkaround,
} from '../../../../stores'
import { animated as a } from '@react-spring/web'
import {
  ShowcaseArrowLeft,
  ShowcaseArrowRight,
} from '../../../assets/svgs/Arrows'
import { rooms } from '../../../../constants'
import { sendEvent } from '../../../../google-analytics'
export const ShowcaseHUD = (props) => {
  const pageState = usePage()
  const { opacity } = useSpring({
    opacity: pageState.page === 'showcase' ? 1 : 0,
    onRest: () => pageState.page !== 'showcase' && setShow(false),
  })
  const [mount, setMount] = useState(false)
  const unmountPopup = () => setMount(false)
  const mountPopup = () => setMount(true)
  const cylinder = useCylinder()
  const workaround = useWorkaround()
  const popup = usePopup()
  const [show, setShow] = useState(false)
  const { setStatus: setCustomFade } = useCustomFade()
  useEffect(() => {}, [mount])
  const clickedRight = () => {
    sendEvent(
      `Categories Right Arrow Button Clicked`,
      'Categories',
      'Categories Button'
    )
    if (!popup.data.hasOwnProperty('popupId')) {
      workaround.setUrl(
        `/showcase?room=${cylinder.room === 5 ? 1 : cylinder.room + 1}&popup=-1`
      )
    } else {
      workaround.setUrl(
        `/showcase?room=${cylinder.room}&popup=${
          popup.popupId == rooms[cylinder.room].hotspots.length - 1
            ? 0
            : popup.popupId + 1
        }`
      )
    }
  }
  const clickedLeft = () => {
    // console.log('clicked',cylinder.room===5?0:cylinder.room+1)
    // workaround.setUrl(`/showcase?room=${cylinder.room===5?0:(cylinder.room+1)}&popup=-1`)
    sendEvent(
      `Categories Left Arrow Button Clicked`,
      'Categories',
      'Categories Button'
    )
    if (!popup.data.hasOwnProperty('popupId')) {
      setCustomFade(true)
      workaround.setUrl(
        `/showcase?room=${cylinder.room === 1 ? 5 : cylinder.room - 1}&popup=-1`
      )
    } else {
      workaround.setUrl(
        `/showcase?room=${cylinder.room}&popup=${
          popup.popupId == 0
            ? rooms[cylinder.room].hotspots.length - 1
            : popup.popupId - 1
        }`
      )
    }
  }
  useEffect(() => {
    pageState.page === 'showcase' && setShow(true)
  }, [pageState.page])
  return (
    !props.fadeStatus &&
    show && (
      <a.div
        style={{
          opacity,
          pointerEvents: pageState.page === 'showcase' ? 'auto' : 'none',
        }}
      >
        <ShowcaseArrowLeft
          onClick={clickedLeft}
          className={css(styles.left, styles.cursor)}
        />
        <ShowcaseArrowRight
          onClick={clickedRight}
          className={css(styles.right, styles.cursor)}
        />
        {<PopupScreen mount={mountPopup} unmount={unmountPopup} />}
      </a.div>
    )
  )
}

const styles = StyleSheet.create({
  left: {
    position: 'absolute',
    left: '1.5%',
    top: '50%',
    width: '1.8%',
    transform: 'translateY(-50%)',
  },
  right: {
    position: 'absolute',
    right: '1.5%',
    top: '50%',
    width: '1.8%',
    transform: 'translateY(-50%)',
  },
  cursor: {
    cursor: 'pointer',
  },
})
