import React, { useState, useEffect } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { commonStyles } from '../commonStyles'
import axios from 'axios'
import { baseUrl } from '../cmsstore'
export const Create = (props) => {
  const [info, setInfo] = useState({})
  const [actualGlb, setActualGlb] = useState({})
  const [actualUsdz, setActualUsdz] = useState({})
  const [submit, setSubmit] = useState(false)
  const [skyboxInfo, setSkyboxInfo] = useState({})
  const [actualHdr, setActualHdr] = useState()
  const [actualImg, setImg] = useState()
  const handleGlbChange = (e) => {
    setInfo({ ...info, glb: e.target.value })
    setActualGlb(e.target.files[0])
  }
  const handleUsdzChange = (e) => {
    setInfo({ ...info, usdz: e.target.value })
    setActualUsdz(e.target.files[0])
  }
  const handleImgChange = (e) => {
    setInfo({ ...info, thumbnail: e.target.value })
    setImg(e.target.files[0])
  }
  useEffect(() => {
    //console.log(props.data)
    if (
      info.glb &&
      info.usdz &&
      info.name &&
      info.productCode &&
      info.tags &&
      !props.data.some((item) => item.productCode === info.productCode)
    ) {
      setSubmit(true)
    } else {
      setSubmit(false)
    }
  }, [info])
  const handleSubmit = async (e) => {
    e.preventDefault()
    props.setLoading(1)
    //console.log({...info,file:actualGlb})
    info['glb'] = actualGlb
    info.usdz = actualUsdz
    let formData = new FormData()
    for (let i in info) {
      // //console.log(i, info[i])
      if (i === 'thumbnail') formData.append(i, actualImg)
      else if(i === "tags") formData.append(i,info[i].split(/[\s,]+/))
      else formData.append(i, info[i])
    }
    info['glb'] = ''
    info.usdz = ''
    info.thumbnail = ''
    // //console.log(formData)
    try {
      let res = await axios.post(baseUrl, formData)
      props.setLoading(2)
    } catch (err) {
      console.log(err)
      props.setLoading(-1)
    }
    // setInfo({file:''})
    props.toggleChanged()
  }
  const handleSkyboxSubmit = async (e) => {
    e.preventDefault()
    let formData = new FormData()
    formData.append('name', skyboxInfo.name)
    formData.append('hdr', actualHdr)
    try {
      props.setLoading(1)
      await axios.post(baseUrl + '/skybox', formData)
      props.setLoading(2)
    } catch (err) {
      props.setLoading(-1)
      //console.log(err)
    }
    props.toggleChanged()
  }
  const handleSkyboxChange = (e) => {
    setActualHdr(e.target.files[0])
    setSkyboxInfo({ ...skyboxInfo, hdr: e.target.value })
  }
  return (
    <div className={css(styles.container)}>
      <h1 className={css(commonStyles.heading)}>Create Entry</h1>
      <form
        className={css(styles.form, commonStyles.paragraph)}
        onSubmit={handleSubmit}
      >
        <label className={css(styles.label)}>Product Code</label>
        <input
          className={css(styles.input)}
          value={info.productCode}
          onChange={(e) => setInfo({ ...info, productCode: e.target.value })}
        />
        <p style={{ color: 'red' }} className={css(styles.label)}>
          {props.data.some((item) => item.productCode === info.productCode) &&
            'Duplicate productCode'}
        </p>
        <label className={css(styles.label)}>Name</label>
        <input
          className={css(styles.input)}
          onChange={(e) => setInfo({ ...info, name: e.target.value })}
          value={info.name}
        />
        <label className={css(styles.label)}>Tags(comma separeted tags)</label>
        <input
          className={css(styles.input)}
          onChange={(e) => setInfo({ ...info, tags: e.target.value })}
          value={info.tags}
        />
        <label className={css(styles.label)}>Description (optional)</label>
        <textarea
          className={css(styles.input)}
          onChange={(e) => setInfo({ ...info, description: e.target.value })}
          value={info.description}
        />
        <label className={css(styles.label)}>Features (optional) Separete features using //</label>
        <textarea
          className={css(styles.input)}
          onChange={(e) => setInfo( {...info, features: e.target.value})}
          value={info.features}
        />
        <label className={css(styles.label)}>Collection Name (optional)</label>
        <input
          className={css(styles.input)}
          onChange={(e) => setInfo( {...info, collectionName: e.target.value})}
          value={info.collection}
        />

        <label className={css(styles.label)}>Category (optional)</label>
        <input
          className={css(styles.input)}
          onChange={(e) => setInfo({ ...info, category: e.target.value })}
          value={info.category}
        />
        <label className={css(styles.label)}>Finish (optional)</label>
        <input
          className={css(styles.input)}
          onChange={(e) => setInfo({ ...info, finish: e.target.value })}
          value={info.finish}
        />
        <label className={css(styles.label)}>Glb File</label>
        <input
          type="file"
          className={css(styles.file)}
          value={info.glb}
          onChange={handleGlbChange}
        />
        <label className={css(styles.label)}>Usdz File</label>
        <input
          type="file"
          className={css(styles.file)}
          value={info.usdz}
          onChange={handleUsdzChange}
        />
        <label className={css(styles.label)}>Thumbnail File</label>
        <input
          type="file"
          className={css(styles.file)}
          value={info.thumbnail}
          onChange={handleImgChange}
        />
        <input disabled={!submit} className={css(styles.input)} type="submit" />
      </form>
      <h1>Add Skybox</h1>

      <form style={{ paddingBottom: '10vh' }} onSubmit={handleSkyboxSubmit}>
        <label className={css(styles.label)}>
          Name
          {props.skyboxes.some((x) => x === skyboxInfo.name) && (
            <span style={{ color: 'red' }}>Duplicate Name</span>
          )}
        </label>
        <input
          className={css(styles.input)}
          onChange={(e) =>
            setSkyboxInfo({ ...skyboxInfo, name: e.target.value })
          }
          value={skyboxInfo.name}
        />
        <label className={css(styles.label)}>Hdr File</label>
        <input
          type="file"
          className={css(styles.file)}
          value={skyboxInfo.hdr}
          onChange={handleSkyboxChange}
        />
        <input
          disabled={
            !(
              skyboxInfo.name &&
              skyboxInfo.hdr &&
              !props.skyboxes.some((x) => x === skyboxInfo.name)
            )
          }
          className={css(styles.input)}
          value="Add skybox"
          type="submit"
        />
      </form>
    </div>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    '@media (max-width: 900px)': {
      display: 'block',
      marginTop: '200px',
      marginLeft: '20%',
    },
    overflowY: 'scroll',
  },
  form: {
    display: 'block',
    width: '100%',
  },
  label: {
    display: 'block',
    fontSize: '0.7rem',
    marginTop: '1%',
  },
  input: {
    display: 'block',
    fontSize: '1rem',
    width: '30%',
  },
})
