import { useEffect } from "react"
import { usePopup } from "../../../stores"
import * as THREE from 'three'

function playAnimationForward(anim) {
    if(!anim) return
    anim.loop = THREE.LoopOnce
    anim.timeScale = 1
    anim.clampWhenFinished = true
    anim.paused = false
    anim.play()
  }
  
  function playAnimationBackward(anim) {
    if(!anim) return
    anim.loop = THREE.LoopOnce
    anim.clampWhenFinished = true
    anim.timeScale = -1
    anim.paused = false
    anim.play()
  }

export const RoomAnimations = ({animations}) => {
    const popup = usePopup()
    useEffect(()=>{
        console.log('popup', popup.data)
        if((!popup.data.popupId && popup.data.popupId!=0) && animations){
            Object.keys(animations)?.map(anim=>{
                playAnimationBackward(animations[anim])
            })
        }
        else if (animations){
            Object.keys(animations)?.map(anim=>{
                playAnimationBackward(animations[anim])
            })
            
            popup.actions?.map(action => {
                console.log('playing ',action, animations[action])
                if(Object.keys(animations).includes(action))playAnimationForward(animations[action])
            })

        }
    },[popup.data,popup.actions])
    return null
}