import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDrag, useGesture } from 'react-use-gesture'
import {
  useCylinder,
  useLocalLoader,
  useNewLoadingScreen,
  useWorkaround,
} from '../../../stores'
import { config, useSpring } from '@react-spring/core'
import { animated as a } from '@react-spring/three'
// import { useControls } from "leva";
import { useGLTF, useTexture } from '@react-three/drei'
import { useHistory } from 'react-router'
import { pageIdx } from '../../../constants'
import { usePage } from '../../../stores'
import { sRGBEncoding, Vector3 } from 'three'
// import { animated as a } from "@react-spring/three";
// import { useLoader } from "@react-three/fiber";

export const Cylinder = () => {
  const cylinder = useCylinder()
  const history = useHistory()
  const pageState = usePage()
  const newLoader = useNewLoadingScreen()
  // const texture = useTexture("https://picsum.photos/3000/400");
  const object = useGLTF(
    './environment/Fin_HSCyclinder.glb',
    'https://www.gstatic.com/draco/versioned/decoders/1.5.0/'
  )
  const tex = useTexture('./environment/cylinderTex.png')
  // const object = useGLTF('./environment/Fin_HomeScreen.glb')
  const workaround = useWorkaround()
  const localLoader = useLocalLoader()
  const [showCylinder, setShow] = useState(true)
  const handleClick = () => {
    // history.push('/showcase?room=0&lookAt=0&popup=null')
    // workaround.setUrl(`/showcase?room=${ cylinder.room }&popup=-1`)
  }
  useEffect(() => {
    // if(showCylinder && pageState.page ==='cylinder' )newLoader.setIsLoading(false) // cylinder has been loaded and is showing, since showCylinder changed we know it came from showcase or its the first load
    if (showCylinder && pageState.page !== 'showcase')
      setTimeout(() => newLoader.setIsLoading(false), [200])
  }, [showCylinder])

  useEffect(() => {
    if (pageState.page !== 'showcase') {
      setShow(true)
    }
  }, [pageState])

  const { opacity } = useSpring({
    from: { opacity: 0 },
    opacity: pageState.page === 'showcase' ? 0 : 1,
    onRest: () => pageState.page === 'showcase' && setShow(false),
    pause: localLoader.progress !== 100,
    config: config.molasses,
  })

  const { scale } = useSpring({
    scale: pageState.page === 'showcase' ? [1, 1, 1] : [1, 1, 1],
    from: { scale: [1, 1, 1] },
    pause: localLoader.progress !== 100,
    // config:config.wobbly,
    config: config.molasses,
  })

  const material = useMemo(() => {
    let met
    object.scene.traverse((x) => {
      if (x.material?.name === 'Cylinder_Baked') {
        met = x.material
        console.log('material: ' + met)
      }
    })
    return met
  }, [object])

  useEffect(() => {
    tex.encoding = sRGBEncoding
    material.map = tex
    // console.log(material)
  }, [object])

  const spring = useSpring({
    from: { zRotate: -Math.PI, opacity: 0 },
    zRotate: 0,
    opacity: 1,
    config: config.wobbly,
    reset: pageState.page === 'cylinder' ? true : false,
  })
  return (
    showCylinder === true && (
      <group>
        <a.group
          style={{ opacity }}
          scale={scale}
          rotation={[
            0,
            pageState.page === 'cylinder' ? Math.PI / 3 : Math.PI,
            0,
          ]}
        >
          <primitive scale={1} object={object.scene} />
        </a.group>
      </group>
    )
  )
}
