import { useSpring } from "@react-spring/core"
import { css, StyleSheet } from "aphrodite"
import { useQR } from "../../stores"
import QRCode from "react-qr-code"
import { useEffect, useRef } from "react"
export const QRModal = (props) => {
    // let asd = {url:`https://master.dy2v8xoym67fn.amplifyapp.com/?android=${AndroidURL}&ios=${IOSIntent}`}
    const qr = useQR()
    const ref = useRef()
    const spring = useSpring({
        opacity:qr.url?1:0
    })
    useEffect(()=>{
        window.addEventListener('click',(e)=>(e.target!==ref.current && qr.url) && qr.setQR(''))
        return () => window.removeEventListener('click',(e)=>(e.target!==ref.current && qr.url) && qr.setQR(''))

    },[])
    return (
        <div style={{pointerEvents:qr.url?'auto':'none'}} className={css(styles.container)}>
            <div ref={ref} className={css(styles.smallContainer)}>
                <h1 className={css(styles.scanMe)}>Scan me for AR!</h1>
                <QRCode
                    fgColor="black"
                    bgColor="white"
                    width='100%'
                    height="100%"
                    // style={{width:'60vw',height:'60vw'}}
                    // style={{position:'absolute',top:0,left:0,width:'100%',height:'100%'}} 
                    value={qr.url} 
                    // className={css(styles.qr)}
                />
                <h1 className={css(styles.close)}>Tap outside to close</h1>
            </div>
        </div>
    )
}

const styles = StyleSheet.create({
    smallContainer:{
        position:'absolute',
        left:'50%',
        top:'50%',
        backgroundColor:'grey',
        padding:'1vh 2vh 1vh 2vh',
        borderRadius:'2vh',
        transform:'scale(1.3) translate(-50%,-45%)',
        textAlign:'center',
        // transform:'scale(1.2)'
    },
    scanMe:{
        color:'white',
        fontSize:'2.2vh'
    },
    qr:{
        // width:'50vh',
        // height:'50vh',
        top:'50%',
        left:'50%',
        backgroundColor:'red',
        position:'absolute',
        // transform:'scale(1%) translate(-50%,-50%)',
        zIndex:1000
    },
    container:{
        backgroundColor:'rgba(0,0,0,0.8)',
        // backgroundColor:
        
        position:'absolute',
        left:0,
        top:0,
        width:'100%',
        height:'100%',
        zIndex:999
    },
    close:{
        textAlign:'right',
        fontSize:'1vh',
        color:'white'
    }
})