import { Vector3 } from 'three'
import { baseUrl } from './cms/cmsstore'

export const gaConfig = {
  enabled: true,
  code: 'G-Z0REKGGSFB',
}

export const pageIdx = {
  fullScreen: 100,
  HUD: 50,
  HUDhigh: 130,
  About: 50,
}

export const categories = [
  { thumbnail: './roomthumbnails/SSAmando.webp', name: 'Amando' },
  { thumbnail: './roomthumbnails/SSLobby.webp', name: 'Lobby' },
  { thumbnail: './roomthumbnails/SSLivingRoom.webp', name: 'Living Room' },
  { thumbnail: './roomthumbnails/SSDoorHandles.webp', name: 'Door Handles' },
  { thumbnail: './roomthumbnails/SSStudy.webp', name: 'Study Room' },
]

/**
 * Level Design Variables
 */

/* Pre-download these panoramas */
export const preloadlist = ['sample.hdr', 'APD_HDRI2.hdr', 'APD_Warm.hdr']

export const SkyboxProducts = 'APD_HDRI2.hdr'
/* Skybox for the main scene */
export const SkyboxMain = 'sample.hdr'

/* Skybox for the Rooms in order 0 - 5 */
export const SkyboxRooms = [
  'APD_HDRI2.hdr',
  'APD_Warm.hdr', // Amando
  'Lob_HDRI1.hdr', //Lobby
  'LR2Var1_HDRI.hdr', //LivingRoom
  'APD_HDRI2.hdr', //Study
  'Lob_HDRI1.hdr', //PopularMortist
]

/*  Enabled Camera Editing using GUI */
export const debugCamera = false

/*  Camera Tween Locations */
export const cameraLocations = {
  cylinder: {
    // Categories Page
    position: new Vector3(22.2, -0.12, 25.8),
    lookAt: new Vector3(-117.92, 2.78, -29.4),
    fov: 36,
  },
  cylinderoutside: {
    // Landing Page
    position: new Vector3(-22.14, 0, 31.5),
    lookAt: new Vector3(-13.64, -0.7, 0),
    fov: 36,
  },
  about: {
    //
    position: new Vector3(35.4, 20, 1.08),
    lookAt: new Vector3(-5, -4, 17.48),
    fov: 50,
  },
  camStart: {
    //
    position: new Vector3(30.72, 0.48, 11.56),
    lookAt: new Vector3(0, 3, 0),
    fov: 50,
  },
}

export const rooms = [
  {
    url: '/environment/Fin_KitchenLShaped.glb', // 0
    category: 'Kitchen Products',
    categoryDescription:
      'Godrej Kitchen Fittings and Systems brings excellence to the arena of kitchen storage and hardware. Innovative and original designs, superior materials and craftsmanship make its range of kitchen storage solutions the smart answer to the unique needs of an Indian kitchen.',
    hotspots: [
      {
        position: [-0.5999999999999999, 3.0499999999999945, 3.9499999999999744],
        parallax: {
          x: 0,
          y: 0,
          z: 0,
        },
        camera: 'below',
        productCode: '4290',
      },
      {
        position: [
          -0.49999999999999983, 2.1499999999999995, 3.6499999999999755,
        ],
        camera: 'above',
        productCode: '4908',
      },
      {
        position: [0.49999999999999983, 2.1499999999999995, 3.6499999999999755],
        // camera:'straight',
        productCode: '4910',
        camera: new Vector3(
          -0.7800000000000009,
          4.479999999999997,
          4.279999999999998
        ),
      },
    ],
    locations: [
      {
        name: 'initial',
        camera: {
          position: new Vector3(-1.8, 0.0, -3.06),
          lookAt: new Vector3(0, 0, 4.26),
          fov: 36,
        },
      },
    ],
  },
  {
    url: '/environment/gltfs/amando/HR/Fin_AmandoDisplay1.gltf', // 1
    lrurl: '/environment/gltfs/amando/LR/Fin_AmandoDisplay1.gltf', // 1
    slrurl: '/environment/gltfs/amando/SLR/Fin_AmandoDisplay1.gltf', // 1
    allproducts: [
      '3085',
      '3084',
      '7585',
      '7586',
      '2831',
      '2830',
      '2832',
      '7236',
      '7237',
      '7007',
      '7008',
      '7102',
      '7101',
      '7103',
      '3080',
      '3081',
      '2843',
      '2840',
      '2841',
      '2842',
      '4089',
      '4088',
      '4090',
      '3074',
      '2847',
      '2844',
      '2845',
      '2846',
      '2835',
      '2833',
      '2834',
      '6637',
      '6638',
      '3083',
      '7589',
      '7590',
      '7591',
      '3082',
      '2459',
      '2457',
      '2458',
      '2460',
      '2456',
      '2453',
      '2454',
      '2455',
      '2837',
      '2836',
      '2838',
      '2839',
      '3903',
      '3904',
      '3905',
      '3077',
    ],
    category: 'Amando',
    categoryDescription:
      'Amando is an international range of premium door handles for every home. Exquisitely crafted and engineered, Amando brings a heightened aesthetic experience to your doors. Very contemporary, Amando is a treat you give your senses, daily',
    hotspots: [
      {
        //C1 R1 3085
        position: [2.174, 1.202 + 0.35, 2.813],
        view: {
          position: [2.4, 1.31, 2.48],
          lookAt: [2.29, 1.31, 3.03],
        },
        camera: 'straight',
        productCode: '3085', // Ask Vivek
      },
      {
        //C1 R2 2831
        position: [2.174, 0.792 + 0.35, 2.813],
        view: {
          position: [2.4, 0.89, 2.48],
          lookAt: [2.29, 0.89, 3.03],
        },
        camera: 'straight',
        productCode: '2831',
      },
      {
        //C1 R3 7236
        position: [2.174, 0.371 + 0.35, 2.813],
        view: {
          position: [2.4, 0.45, 2.48],
          lookAt: [2.29, 0.45, 3.03],
        },
        camera: 'straight',
        productCode: '7236',
      },
      {
        //C1 R4 7007
        position: [2.174, -0.08 + 0.35, 2.813],
        view: {
          position: [2.4, 0.03, 2.48],
          lookAt: [2.29, 0.03, 3.03],
        },
        camera: 'straight',
        productCode: '7007',
      },
      {
        //C1 R5 7102
        position: [2.174, -0.445 + 0.35, 2.813],
        view: {
          position: [2.4, -0.38, 2.48],
          lookAt: [2.29, -0.38, 3.03],
        },
        camera: 'straight',
        productCode: '7102',
      },
      {
        //C2 R1 2843
        position: [1.202, 1.202 + 0.35, 3.459],
        view: {
          position: [1.56, 1.32, 2.76],
          lookAt: [1.2, 1.32, 3.43],
        },
        camera: 'straight',
        productCode: '2843',
      },
      {
        //C2 R2 4089
        position: [1.202, 0.792 + 0.35, 3.459],
        view: {
          position: [1.56, 0.9, 2.76],
          lookAt: [1.2, 0.9, 3.43],
        },
        camera: 'straight',
        productCode: '4089',
      },
      {
        //C2 R3 2847
        position: [1.202, 0.371 + 0.35, 3.459],
        view: {
          position: [1.56, 0.45, 2.76],
          lookAt: [1.2, 0.45, 3.43],
        },
        camera: 'straight',
        productCode: '2847',
      },
      {
        //C2 R4 2835
        position: [1.202, -0.08 + 0.35, 3.459],
        view: {
          position: [1.56, 0.03, 2.76],
          lookAt: [1.2, 0.03, 3.43],
        },
        camera: 'straight',
        productCode: '2835',
      },
      {
        //C2 R5 6637
        position: [1.202, -0.445 + 0.35, 3.459],
        view: {
          position: [1.56, -0.38, 2.76],
          lookAt: [1.2, -0.38, 3.43],
        },
        camera: 'straight',
        productCode: '6637',
      },
      {
        //C3 R1 3083
        position: [0.11, 1.202 + 0.35, 3.47],
        view: {
          position: [0.54, 1.3, 2.82],
          lookAt: [0.12, 1.3, 3.47],
        },
        camera: 'straight',
        productCode: '3083',
      },
      {
        //C3 R2 2459
        position: [0.11, 0.792 + 0.35, 3.47],
        view: {
          position: [0.66, 0.92, 2.7],
          lookAt: [-0.01, 0.92, 3.48],
        },
        camera: 'straight',
        productCode: '2459',
      },
      {
        //C3 R3 2456
        position: [0.11, 0.371 + 0.35, 3.47],
        view: {
          position: [0.6, 0.45, 2.82],
          lookAt: [0.1, 0.45, 3.47],
        },
        camera: 'straight',
        productCode: '2456',
      },
      {
        //C3 R4 2837
        position: [0.11, -0.08 + 0.35, 3.47],
        view: {
          position: [0.6, 0.03, 2.82],
          lookAt: [0.1, 0.03, 3.47],
        },
        camera: 'straight',
        productCode: '2837',
      },
      {
        //C3 R5 3903
        position: [0.11, -0.445 + 0.35, 3.47],
        view: {
          position: [0.6, -0.4, 2.82],
          lookAt: [0.1, -0.4, 3.47],
        },
        camera: 'straight',
        productCode: '3903',
      },
    ],
    locations: [
      {
        name: 'initial',
        camera: {
          position: {
            x: 0.84,
            y: 0.0,
            z: -4.08,
            prototype: Vector3,
          },
          // "lookat":{"x":-0.4999999999999999,"y":-0.02000000000000005,"z":2.480000000000001,prototype:Vector3},
          lookat: {
            x: 0.46,
            y: 0.2,
            z: -0.04,
            prototype: Vector3,
          },
          // lookAt:new Vector3(0,0,0),
          fov: 31,
        },
      },
    ],
  },
  {
    url: '/environment/gltfs/lobby/HRRevert/Fin_Lobby.gltf', // 2
    lrurl: '/environment/gltfs/lobby/LR/Fin_Lobby.gltf', // 2
    slrurl: '/environment/gltfs/lobby/SLR/Fin_Lobby.gltf', // 2
    allproducts: [
      '2300',
      '6668',
      '6667',
      '4101',
      '3000',
      '2997',
      '3345',
      '3349',
      '4967',
      '2998',
      '6672',
      '6671',
      '3397',
      '3399',
      '4912',
      '4913',
      '6362',
      '6359',
      '6677',
      '6678',
      '4908',
      '4909',
      '3231',
      '3226',
      '6111',
      '5102',
      '5104',
      '6112',
      '6113',
      '6114',
      '6109',
      '5945',
      '5103',
      '6115',
      '6116',
      '6110',
    ],
    //! Incoming change
    // url: '/environment/gltfs/lobby/HRPNG/Fin_Lobby.gltf', // 2
    // // url: '/environment/Fin_Lobby.glb', // 2
    // allproducts:['2300', '6668', '6667', '4101', '3000', '2997', '3345', '3349', '4967', '2998', '6672', '6671', '3397', '3399', '4912', '4913', '6362', '6359', '6677', '6678', '4908', '4909', '3231', '3226', '6111', '5102', '5104', '6112', '6113', '6114', '6109', '5945', '5103', '6115', '6116', '6110'],
    category: 'Lobby',
    categoryDescription:
      'Upgrade to superior locking systems and superbly crafted and engineered door handles, so that your front door has the best of safety and aesthetics.',
    hotspots: [
      {
        //2300 - D2 Safe
        position: [-2.76, -0.912 + 0.4, 2.366],
        view: {
          position: [-1.98, -0.91, 3.06],
          lookAt: [-2.41, -0.91, 2.5],
        },
        camera: 'straight',
        productCode: '2300',
      },
      {
        hoverLeft: true,
        //6668 - D4 Safe
        position: [-0.196, -0.934 + 0.325, -3.88],
        view: {
          position: [0.11999999999999994, -1.05, -3.3],
          lookAt: [0.83, -1.05, -3.92],
        },
        camera: 'straight',
        productCode: '6668',
      },
      {
        //4101 - D4 Safe
        hoverLeft: true,
        position: [-0.196, -0.934 + 0.68, -3.88],
        view: {
          position: [0.3, -0.74, -3.42],
          lookAt: [0.69, -0.74, -3.92],
        },
        camera: 'straight',
        productCode: '4101',
      },
      {
        //3345 - D4 Main
        hoverLeft: true,
        position: [-0.196, -0.934 + 0.87, -3.88],
        view: {
          position: [0.78, -0.96, -4.560000000000008],
          lookAt: [0.41, -0.96, -4.06],
          time: 2150,
        },
        camera: 'straight',
        productCode: '3345',
        actions: ['D4Safe_Action', 'D4Main_Action'],
      },
      {
        //4967 - D3 Safe
        position: [-2.76, -0.934 + 0.415, -2.169 + 0.3],
        view: {
          position: [-2.1, -0.92, -1.32],
          lookAt: [-2.49, -0.92, -1.7],
        },
        camera: 'straight',
        productCode: '4967',
      },
      {
        //6672 - D3 Main
        position: [-2.76, -0.912 + 0.6, -2.169 + 0.3],
        view: {
          position: [-2.04, -0.92, -2.4],
          lookAt: [-4.05, -0.92, -1.82],
        },
        camera: 'straight',
        productCode: '6672',
        actions: ['D3Safe_Action'],
      },
      {
        //3397 - D3 Main
        position: [-2.76, -0.1, -2.169 + 0.3],
        view: {
          position: [-3.36, -1.17, -1.51],
          lookAt: [-2.48, -1.17, -1.76],
          time: 2150,
        },
        camera: 'straight',
        productCode: '3397',
        actions: ['D3Safe_Action', 'D3Main_Action'],
      },
      {
        //4912 - D2 Main
        position: [-2.76, -0.912 + 0.6, 2.366],
        view: {
          position: [-1.92, -0.91, 1.8],
          lookAt: [-3.88, -0.91, 2.45],
        },
        camera: 'straight',
        productCode: '4912',
        actions: ['D2Safe_Action'],
      },
      {
        //6362 - D2 Main
        position: [-2.76, -0.912 + 0.817, 2.366],
        view: {
          position: [-3.42, -1.18, 2.22],
          lookAt: [-3.14, -1.18, 2.49],
          time: 2150,
        },
        camera: 'straight',
        productCode: '6362',
        actions: ['D2Safe_Action', 'D2Main_Action'],
      },
      {
        //6677 - D4 Main
        position: [-0.196, -0.934 + 0.5, -3.88],
        view: {
          position: [0.24, -0.93, -3.42],
          lookAt: [0.69, -0.93, -3.98],
        },
        hoverLeft: true,
        camera: 'straight',
        productCode: '6677',
        actions: ['D4Safe_Action'],
      },
      {
        //4908 - D1 Main
        position: [0.491, -0.912 + 0.5, 3.864],
        view: {
          position: [0.18, -0.92, 3.3],
          lookAt: [-0.66, -0.92, 4],
        },
        camera: 'straight',
        productCode: '4908',
        actions: ['D1Safe_Action'],
      },
      {
        //3231 - D1 Main
        position: [0.491, -0.25, 3.864],
        view: {
          position: [-0.6, -1.14, 4.62],
          lookAt: [-0.24, -1.14, 4.05],
          time: 2150,
        },
        camera: 'straight',
        productCode: '3231',
        actions: ['D1Safe_Action', 'D1Main_Action'],
      },
      {
        //6111 - D1 Safe
        position: [0.491, -0.58, 3.864],
        view: {
          position: [0.06, -0.92, 3.24],
          lookAt: [-0.51, -0.92, 3.82],
        },
        camera: 'straight',
        productCode: '6111',
      },
    ],
    locations: [
      {
        name: 'initial',
        camera: {
          position: {
            x: 8.64,
            y: -0.72,
            z: 0.0,
            prototype: Vector3,
          },
          // "lookat":{"x":-0.4999999999999999,"y":-0.02000000000000005,"z":2.480000000000001,prototype:Vector3},
          lookat: {
            x: 0.0,
            y: -0.72,
            z: 0.0,
            prototype: Vector3,
          },
          // lookAt:new Vector3(0,0,0),
          fov: 32,
        },
      },
    ],
  },
  {
    url: '/environment/gltfs/livingroom/HR/Fin_LivingRoom.gltf',
    lrurl: '/environment/gltfs/livingroom/LR/Fin_LivingRoom.gltf',
    slrurl: '/environment/gltfs/livingroom/SLR/Fin_LivingRoom.gltf',
    allproducts: [
      '7587',
      '7588',
      '3086',
      '3087',
      '4093',
      '4094',
      '4095',
      '3075',
      '6638',
      '6637',
      '4085',
      '4086',
      '4087',
      '3073',
      '7237',
      '7236',
      '5959',
    ],
    category: 'Living Room',
    categoryDescription:
      'Let your living room express your fine sense of aesthetics, with a range of exquisite door handles.',
    hotspots: [
      {
        //7587
        position: [-1.683, 0.857 + 0.25, -1.301],
        view: {
          position: [-1.33, 1.19, -0.95],
          lookAt: [-1.48, 1.01, -1.3],
        },
        camera: 'straight',
        productCode: '7587',
        actions: ['7213_1Action"', '7213_2Action'],
        hoverLeft: true,
      },
      {
        //4093
        position: [1.136, 0.857 + 0.3, -1.301],
        view: {
          position: [0.81, 0.93, -0.77],
          lookAt: [1.33, 0.93, -1.36],
        },
        camera: 'straight',
        productCode: '4093',
        actions: ['7213_1Action"', '7213_2Action'],
        hoverLeft: true,
      },
      {
        //6638
        position: [2.322, 0.857 + 0.3, -1],
        view: {
          position: [1.92, 1.34, -0.76],
          lookAt: [2.38, 0.98, -0.87],
        },
        camera: 'straight',
        productCode: '6638',
        actions: ['7213_1Action"', '7213_2Action'],
        hoverLeft: true,
      },
      {
        //4085
        position: [-0.39, 0.857 + 0.4, -3.973],
        view: {
          position: [-0.22, 1.26, -3.66],
          lookAt: [-0.31, 0.97, -4.01],
        },
        camera: 'straight',
        productCode: '4085',
        actions: ['7213_1Action"', '7213_2Action'],
        hoverLeft: true,
      },
      {
        //7237
        position: [0.751, 0.857 + 0.4, -3.575],
        view: {
          position: [0.48, 1.44, -3.54],
          lookAt: [0.82, 0.97, -3.65],
        },
        camera: 'straight',
        productCode: '7237',
        actions: ['7213_1Action"', '7213_2Action'],
        hoverLeft: true,
      },
      {
        //5959
        position: [2.253, 2.2, -0.307],
        view: {
          position: [1.7, 1.92, 0.08],
          lookAt: [3.44, 2.33, -0.38],
        },
        camera: 'straight',
        productCode: '5959',
        actions: ['7213_1Action"', '7213_2Action'],
        hoverLeft: true,
      },
    ],
    locations: [
      {
        name: 'initial',
        camera: {
          position: {
            x: -0.84,
            y: 0.84,
            z: 3.42,
            prototype: Vector3,
          },
          // "lookat":{"x":-0.4999999999999999,"y":-0.02000000000000005,"z":2.480000000000001,prototype:Vector3},
          lookat: {
            x: 0.18,
            y: 0.84,
            z: 0.0,
            prototype: Vector3,
          },
          // lookAt:new Vector3(0,0,0),
          fov: 36,
        },
      },
    ],
  },
  {
    url: '/environment/gltfs/doorhandles/HR/Fin_MortiseDisplay.gltf',
    lrurl: '/environment/gltfs/doorhandles/LR/Fin_MortiseDisplay.gltf',
    slrurl: '/environment/gltfs/doorhandles/SLR/Fin_MortiseDisplay.gltf',
    allproducts: [
      '6757',
      '6759',
      '6696',
      '6698',
      '7117',
      '7119',
      '6690',
      '6692',
      '8312',
      '7946',
      '8165',
      '7351',
      '8222',
      '7355',
      '2268',
      '2270',
      '8857',
      '7947',
      '7362',
      '2264',
      '2266',
      '5994',
      '5990',
      '2485',
      '3053',
      '2481',
      '3054',
      '2479',
      '3047',
      '2475',
      '3043',
      '5988',
      '5984',
      '7945',
      '2310',
      '7357',
    ],
    category: 'Door Handles',
    categoryDescription:
      'Take your pick from a spectrum of mortise door handles that are a perfect combination of safety science and sheer good looks.',
    hotspots: [
      {
        //6757 - NEH07
        position: [2.094, 1.504 + 0.2, -1.018],
        view: {
          position: [1.74, 1.56, -1.44],
          lookAt: [2.07, 1.56, -1.04],
        },
        camera: 'straight',
        productCode: '6757',
      },
      {
        //6696 - NEH 10
        position: [2.094, 1.504 - 0.4, -1.018],
        view: {
          position: [1.74, 0.98, -1.44],
          lookAt: [2.02, 0.98, -1.05],
        },
        camera: 'straight',
        productCode: '6696',
      },
      {
        //7117 NEH 06
        position: [2.094, 1.776 + 0.2, -0.723],
        view: {
          position: [1.68, 1.82, -1.02],
          lookAt: [1.96, 1.82, -0.77],
        },
        camera: 'straight',
        productCode: '7117',
      },
      {
        //6690 NEH 09
        position: [2.094, 1.221 + 0.2, -0.723],
        view: {
          position: [1.68, 1.25, -0.96],
          lookAt: [1.94, 1.25, -0.76],
        },
        camera: 'straight',
        productCode: '6690',
      },
      {
        //8312 Victoria
        position: [2.094, 1.221 + 0.2, -0.15],
        view: {
          position: [1.8, 1.27, -0.48],
          lookAt: [2.02, 1.27, -0.18],
        },
        camera: 'straight',
        productCode: '8312',
      },
      {
        //8165 Gloria
        position: [2.094, 1.776 + 0.21, -0.15],
        view: {
          position: [1.8, 1.82, -0.36],
          lookAt: [2.0, 1.82, -0.18],
        },
        camera: 'straight',
        productCode: '8165',
      },
      {
        //2268 MEG
        position: [2.094, 1.776 + 0.21, 0.3625],
        view: {
          position: [1.8, 1.82, 0.12],
          lookAt: [2.03, 1.82, 0.4],
        },
        camera: 'straight',
        productCode: '2268',
      },
      {
        //8857 Oliver
        position: [2.094, 1.504 + 0.2, -0.45],
        view: {
          position: [1.69, 1.55, -0.64],
          lookAt: [1.97, 1.55, -0.46],
        },
        camera: 'straight',
        productCode: '8857',
      },
      {
        //2264 EVA
        position: [2.094, 1.504 + 0.2, 0.1],
        view: {
          position: [1.74, 1.55, -0.12],
          lookAt: [2.01, 1.55, 0.1],
        },
        camera: 'straight',
        productCode: '2264',
      },
      {
        //5994 NEH 16
        position: [2.094, 1.504 + 0.25, 1.575],
        view: {
          position: [1.88, 1.58, 1.47],
          lookAt: [2.04, 1.58, 1.63],
        },
        camera: 'straight',
        productCode: '5994',
      },
      {
        //2485 NEH 18
        position: [2.094, 1.504 + 0.25, 2.125],
        view: {
          position: [1.87, 1.58, 1.98],
          lookAt: [2.05, 1.58, 2.2],
        },
        camera: 'straight',
        productCode: '2485',
      },
      {
        //2479 NEH 17
        position: [2.094, 1.232 + 0.28, 1.9],
        view: {
          position: [1.74, 1.25, 1.63],
          lookAt: [2.02, 1.25, 1.93],
        },
        camera: 'straight',
        productCode: '2479',
      },
      {
        //5988 NEH 15
        position: [2.094, 2.075, 1.9],
        view: {
          position: [1.86, 1.84, 1.62],
          lookAt: [2.09, 1.84, 1.93],
        },
        camera: 'straight',
        productCode: '5988',
      },
      {
        //7945 Matiz
        position: [2.094, 2.075, 2.42],
        view: {
          position: [1.8, 1.86, 2.28],
          lookAt: [1.96, 1.86, 2.5],
        },
        camera: 'straight',
        productCode: '7945',
      },
    ],
    locations: [
      {
        name: 'initial',
        camera: {
          position: {
            x: -2.46,
            y: 1.5,
            z: -1.62,
            prototype: Vector3,
          },
          // "lookat":{"x":-0.4999999999999999,"y":-0.02000000000000005,"z":2.480000000000001,prototype:Vector3},
          lookat: {
            x: 0.0,
            y: 1.5,
            z: 0.0,
            prototype: Vector3,
          },
          // lookAt:new Vector3(0,0,0),
          fov: 36,
        },
      },
    ],
  },
  {
    url: '/environment/gltfs/study/HR/Fin_Study.gltf',
    lrurl: '/environment/gltfs/study/LR/Fin_Study.gltf',
    slrurl: '/environment/gltfs/study/SLR/Fin_Study.gltf',
    allproducts: [
      '4374',
      '2574',
      '8012',
      '8010',
      '8178',
      '7836',
      '2999',
      '7213',
      '7214',
      '7215',
    ],
    category: 'Study Room',
    categoryDescription:
      'Safety, reliability and quality: these are the hallmarks of our range of architectural fittings furniture locks and architectural hardware.',
    hotspots: [
      {
        //Drawer 1 4374
        position: [-0.75, -1.335 + 0.24, -0.32],
        view: {
          position: [-0.92, -1.29, -0.54],
          lookAt: [-0.75, -1.29, -0.32],
        },
        camera: 'straight',
        productCode: '4374',
        actions: ['4374Key_Action', 'Drawer4374_Action'],
      },
      {
        //Drawer 2 8012
        position: [-0.75, -1.588 + 0.32, -0.32],
        view: {
          position: [-0.92, -1.43, -0.54],
          lookAt: [-0.75, -1.43, -0.32],
        },
        camera: 'straight',
        productCode: '8012',
        actions: ['8012Key_Action', 'Drawer8012_Action'],
      },
      {
        //Drawer Channel 8178
        position: [-0.883, -1.335 + 0.25, -0.13],
        view: {
          position: [-0.97, -1.1, 0.2],
          lookAt: [-0.78, -1.33, -0.09],
        },
        camera: 'straight',
        productCode: '8178',
        actions: ['4374Key_Action', 'Drawer4374_Action'],
      },
      {
        //Wardrobe 2999
        position: [0.445, -0.89 + 0.29, -1.85],
        view: {
          position: [0.18, -0.88, -1.5],
          lookAt: [0.5, -0.88, -1.71],
        },
        camera: 'straight',
        productCode: '2999',
        actions: ['8012KeyAction', 'W2Door1Action'],
      },
      {
        //Wardrobe 7213
        position: [0.11, -0.656 + 0.25, -1.923],
        view: {
          position: [0.31, -0.46, -1.93],
          lookAt: [0.05, -0.76, -2.12],
        },
        camera: 'straight',
        productCode: '7213',
        actions: ['7213_1Action"', '7213_2Action'],
        hoverLeft: true,
      },
    ],
    locations: [
      {
        name: 'initial',
        camera: {
          position: {
            x: 3.24,
            y: -1.09,
            z: 0.96,
            prototype: Vector3,
          },
          // "lookat":{"x":-0.4999999999999999,"y":-0.02000000000000005,"z":2.480000000000001,prototype:Vector3},
          lookat: {
            x: 1.43,
            y: -1.09,
            z: -0.15,
            prototype: Vector3,
          },
          // lookAt:new Vector3(0,0,0),
          fov: 36,
        },
      },
    ],
  },
  {
    url: '/environment/gltfs/amando/HR/Fin_AmandoDisplay1.gltf',
    lrurl: '/environment/gltfs/amando/LR/Fin_AmandoDisplay1.gltf',
    slrurl: '/environment/gltfs/amando/SLR/Fin_AmandoDisplay1.gltf',
    category: 'Lobby',
    categoryDescription:
      'Occaecat nisi duis id labore labore labore esse in sunt. Cupidatat cupidatat sunt amet cillum nostrud ut ipsum sunt eiusmod. Mollit culpa fugiat est tempor ut eu ex. Ipsum quis ut non sit ullamco non. Cillum sit aute veniam esse duis aliqua. Commodo tempor veniam aliqua ex aute occaecat occaecat est aute proident est voluptate dolor.',
    hotspots: [
      {
        position: [0, 0, 100],
      },
      {
        position: [20, 1, 30],
      },
    ],
    locations: [
      {
        name: 'initial',
        camera: {
          position: {
            x: 2.700000000000003,
            y: -0.05999999999999983 + 0.5,
            z: -1.3800000000000008,
            prototype: Vector3,
          },
          // "lookat":{"x":-0.4999999999999999,"y":-0.02000000000000005,"z":2.480000000000001,prototype:Vector3},
          lookat: {
            x: -0.059999999999999526,
            y: -0.08000000000000004 + 0.5,
            z: 4.080000000000001,
            prototype: Vector3,
          },
          // lookAt:new Vector3(0,0,0),
          fov: 50,
        },
      },
    ],
  },
]
