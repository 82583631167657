import React, { useEffect, useState } from 'react'
import { pageIdx } from '../../../../constants'
import {
  useCustomFade,
  useCylinder,
  usePage,
  useParallax,
  usePopup,
  useRoom,
} from '../../../../stores'
import { StyleSheet, css } from 'aphrodite'
import { useHistory } from 'react-router'
import { useSpring } from '@react-spring/core'
import { animated as a } from '@react-spring/web'
import { ScrollStatus } from '../../../assets/svgs/ScrollStatus'
import {
  buttonStylesV2,
  colorThemes,
  generalStyles,
  textStyles,
  textStylesV2,
} from '../../../../appStyles'
import { ARKit, SmallArrowRight } from '../../../assets/svgs/ARKit'
import MouseClick from '../cylinder/mouseclick/MouseClick'
import './mouseclick/index.css'
import { CylinderLeftRight } from './CylinderLeftRight'
import { Disclaimer } from './Disclaimer'
import { sendEvent } from '../../../../google-analytics'

export const CylinderOutside = (props) => {
  const history = useHistory()
  const cylinder = useCylinder()
  const pageState = usePage()
  const popup = usePopup()
  const [showDisclaimer, setDisclaimer] = useState(false)
  const [show, setShow] = useState(false)
  const [fadeOut, setFade] = useState(false)
  const [localInfo, setLocalInfo] = useState({})
  const { shouldMove } = useParallax()
  const { setStatus: setCustomFade, status: fadeStatus } = useCustomFade()
  const spring = useSpring({
    from: { opacity: 0 },
    opacity: pageState.page === 'cylinder' && !fadeOut ? 0.85 : 0,
    onRest: () => pageState.page !== 'cylinder' && setShow(false),
  })
  const bottomTextSpring = useSpring({
    from: { opacity: 0 },
    opacity:
      (pageState.page === 'cylinder' || pageState.page == 'showcase') &&
      !fadeOut
        ? 0.85
        : 0,
  })
  useEffect(() => {
    // console.log(cylinder.info)
    setFade(true)
    setTimeout(() => {
      setFade(false)
      setLocalInfo(cylinder.info)
    }, 200)
    // setLocalInfo(cylinder.info)
  }, [cylinder.info])
  useEffect(() => {
    pageState.page === 'cylinder' && setShow(true)
    setDisclaimer(false)
  }, [pageState.page])
  const enterRoom = () => {
    //Fade Out Here
    setCustomFade(true)
    history.push(`/showcase?room=${cylinder.room}&popup=-1`)
    sendEvent(
      `Navigate to 3D Space button clicked`,
      'Cylinder Page',
      'Navigate to 3D Space Button'
    )
  }

  const onSearch = () => {
    sendEvent(`Search button clicked`, 'Search bar', 'Search')
    history.push('/categories')
  }
  const goBack = () => {
    if (pageState.page === 'about') {
      sendEvent(`About back button clicked`, 'About Back Button', 'Back Button')
      return history.goBack()
    }

    if (pageState.page === 'cylinder') {
      sendEvent(
        `Cyclinder page back button clicked`,
        'Page Back Button',
        'Back Button'
      )
      history.push('/')
    } else if (
      pageState.page === 'mv' ||
      pageState.page === 'categories' ||
      pageState.page === 'catalog'
    ) {
      sendEvent(
        `Categories/Catalog/mv back button clicked`,
        'Categories Back Button',
        'Back Button'
      )
      if (popup.data.popupId != -1 && popup.data.popupId != null) {
        history.push(
          `/showcase?room=${cylinder.room}&popup=${popup.data.popupId}`
        )
      } else {
        if (history.length > 2) {
          history.goBack()
        } else history.push('/')
      }
    } else if (popup.data.popupId != null) {
      // Bug when opening categories from inside the cylinder, fix sphagetti
      if (popup.data.popupId != -1) {
        history.push(`/showcase?room=${cylinder.room}&popup=-1`)
      } else {
        history.push(`/cylinder?room=${cylinder.room}`)
      }
    } else {
      if (history.location.pathname != '/categories') setCustomFade(true)
      sendEvent(`Page back button clicked`, 'Page Back Button', 'Back Button')
      history.push(`/cylinder?room=${cylinder.room}`)
    }
  }
  const room = useRoom()
  // console.log(room.d)
  const { opacity } = useSpring({
    opacity: pageState.page === 'cylinder' ? 0.85 : 0,
  })
  const { transform, opacity: bottomLineOpacity } = useSpring({
    transform: `translateX(${(cylinder.room - 1) * 100}%)`,
    opacity:
      pageState.page === 'cylinder' || pageState.page == 'showcase' ? 0.85 : 0,
    // left: `${cylinder.room * 16}vw`,
  })
  const cylinderAndShowcase = useSpring({
    opacity:
      pageState.page === 'cylinder' ||
      pageState.page === 'showcase' ||
      pageState.page === 'categories' ||
      pageState.page === 'catalog' ||
      pageState.page === 'mv' ||
      pageState.page === 'about'
        ? 1
        : 0,
  })
  const bottomButtonOnClick = (idx) => {
    if (pageState.page === 'cylinder') {
      sendEvent(`Menu button: ${idx} clicked`, 'Cylinder Page', 'Menu Button')
      history.push(`/cylinder?room=${idx}`)
    } else {
      sendEvent(`Menu button: ${idx} clicked`, 'Popup Page', 'Menu Button')
      history.push(`/showcase?room=${idx}&popup=-1`)
    }
  }
  return (
    <>
      {!fadeStatus && (
        <>
          <Disclaimer setDisclaimer={setDisclaimer} show={showDisclaimer} />
          <CylinderLeftRight shouldShow={pageState.page === 'cylinder'} />
          {/* <p>Plx:{shouldMove?'yes':"no"}</p> */}
          <a.div
            style={{
              pointerEvents:
                pageState.page === 'cylinder' || pageState.page === 'mv'
                  ? 'auto'
                  : 'none',
              ...spring,
            }}
            className={css(styles.container)}
          >
            {/* <button style={{}} onClick={()=>history.push('/cylinder?room=0')}>Go closer or sth</button>
                <button className={css(styles.button)} onClick={enterRoom}>Enter room</button> */}
            <h1
              className={css(textStylesV2.HeadlineM, textStylesV2.GodrejGreen)}
            >
              {localInfo.category}
            </h1>
            <p className={css(textStylesV2.BodyR, textStylesV2.BasicColor)}>
              {localInfo.categoryDescription}
            </p>
            <div
              onClick={enterRoom}
              style={{
                pointerEvents: pageState.page === 'cylinder' ? 'auto' : 'none',
                cursor: 'pointer',
              }}
              className={css(buttonStylesV2.basicCTA, styles.CTA)}
            >
              Navigate To 3D Space
            </div>
            {/* <p className={css(textStyles.para20, styles.roboto)}>
              SWIPE TO SPIN!{' '}
            </p> */}
          </a.div>
          {/* <a.p
            style={bottomTextSpring}
            className={css(textStyles.para20, styles.roboto, styles.bottomText)}
          >
            {`0${cylinder.room + 1} ` +
              (room.data.category?.split(' ')[0] ||
                localInfo.category?.split(' ')[0])}
          </a.p> */}

          <a.div
            style={{ transform: transform, opacity: bottomLineOpacity }}
            className={css(styles.floatingCategoryContainer)}
          >
            <div
              style={{
                marginLeft: '4vw',
                marginRight: '4vw',
                borderBottom: `2px solid ${colorThemes.textBlue}`,
              }}
            ></div>
          </a.div>
          <a.div
            className={css(styles.bottomText)}
            style={{
              ...bottomTextSpring,
              pointerEvents:
                pageState.page === 'showcase' || pageState.page === 'cylinder'
                  ? 'auto'
                  : 'none',
            }}
          >
            {/* <div
              className={css(
                styles.categoryContainer,
                textStylesV2.LabelL,
                cylinder.room == 0
                  ? textStylesV2.ActiveColor
                  : textStylesV2.DeactiveColor
              )}
            >
              Kitchen Products
            </div> */}

            <div className={css(styles.mouseDiv)}>
              <Mouse />
            </div>
            <div
              onClick={() => bottomButtonOnClick(1)}
              className={css(
                styles.categoryContainer,
                textStylesV2.LabelL,
                cylinder.room == 1
                  ? textStylesV2.ActiveColor
                  : textStylesV2.DeactiveColor
              )}
            >
              Amando
            </div>
            <div
              onClick={() => bottomButtonOnClick(2)}
              className={css(
                styles.categoryContainer,
                textStylesV2.LabelL,
                cylinder.room == 2
                  ? textStylesV2.ActiveColor
                  : textStylesV2.DeactiveColor
              )}
            >
              Lobby
            </div>
            <div
              onClick={() => bottomButtonOnClick(3)}
              className={css(
                styles.categoryContainer,
                textStylesV2.LabelL,
                cylinder.room == 3
                  ? textStylesV2.ActiveColor
                  : textStylesV2.DeactiveColor
              )}
            >
              Living Room
            </div>
            <div
              onClick={() => bottomButtonOnClick(4)}
              className={css(
                styles.categoryContainer,
                textStylesV2.LabelL,
                cylinder.room == 4
                  ? textStylesV2.ActiveColor
                  : textStylesV2.DeactiveColor
              )}
            >
              Door Handles
            </div>
            <div
              onClick={() => bottomButtonOnClick(5)}
              className={css(
                styles.categoryContainer,
                textStylesV2.LabelL,
                cylinder.room == 5
                  ? textStylesV2.ActiveColor
                  : textStylesV2.DeactiveColor
              )}
            >
              Study Room
            </div>
          </a.div>
          <a.div style={{ opacity }}>
            <ScrollStatus
              className={css(styles.scrollStatus)}
              room={cylinder.room}
            />
          </a.div>
          <a.div
            style={{
              pointerEvents:
                pageState.page === 'cylinder' ||
                pageState.page === 'showcase' ||
                pageState.page === 'mv'
                  ? 'auto'
                  : 'none',
              ...cylinderAndShowcase,
            }}
          >
            <div className={css(styles.navBar)}>
              {(pageState.page == 'categories' ||
                pageState.page == 'catalog' ||
                pageState.page == 'mv') && (
                <>
                  <div
                    style={{ margin: 0 }}
                    className={css(textStylesV2.LabelNav)}
                  ></div>
                  <div
                    style={{ margin: 0 }}
                    className={css(textStylesV2.LabelNav)}
                  ></div>
                  <div
                    style={{ margin: 0 }}
                    className={css(textStylesV2.LabelNav)}
                  ></div>
                </>
              )}
              {pageState.page != 'categories' &&
                pageState.page != 'catalog' &&
                pageState.page != 'mv' && (
                  <>
                    <h1
                      onClick={() => {
                        pageState.page === 'about'
                          ? history.goBack()
                          : history.push('/about')
                        pageState.page === 'about'
                          ? sendEvent(
                              `Closed About Section`,
                              'About Section',
                              'About'
                            )
                          : sendEvent(
                              `Opened About Section`,
                              'About Section',
                              'About'
                            )
                      }}
                      style={{ margin: 0 }}
                      className={css(
                        textStylesV2.LabelNav,
                        textStylesV2.BasicColor,
                        styles.hudButtonsEffects
                      )}
                    >
                      About
                    </h1>
                    {/* <h1
                      style={{ margin: 0 }}
                      className={css(
                        textStylesV2.LabelNav,
                        textStylesV2.BasicColor,
                        styles.hudButtonsEffects
                      )}
                    >
                      Share
                    </h1> */}
                    <h1
                      style={{ margin: 0 }}
                      onClick={onSearch}
                      className={css(
                        textStylesV2.LabelNav,
                        textStylesV2.BasicColor,
                        styles.hudButtonsEffects
                      )}
                    >
                      Search
                    </h1>
                    <h1
                      style={{ margin: 0 }}
                      onClick={() => {
                        setDisclaimer((state) => !state)
                        sendEvent(
                          `Disclaimer button clicked`,
                          'Disclaimer Section',
                          'Disclaimer'
                        )
                      }}
                      className={css(
                        textStylesV2.LabelNav,
                        textStylesV2.BasicColor,
                        styles.hudButtonsEffects,
                        styles.smallerFont
                      )}
                    >
                      {/* DISCLAIMER */}
                      Disclaimer
                    </h1>
                  </>
                )}
              <h1
                style={{ margin: 0 }}
                onClick={goBack}
                className={css(
                  textStylesV2.LabelNav,
                  textStylesV2.BasicColor,
                  styles.hudButtonsEffects,
                  styles.backZindex
                )}
              >
                Back
              </h1>

              {/* <h1
                onClick={goBack}
                style={{ margin: 0 }}
                className={css(
                  textStylesV2.LabelNav,
                  styles.hudButtonsEffects,
                  buttonStylesV2.back
                )}
              >
                <div className={css(styles.backIconContainer)}>
                  <img
                  // height='100%'
                    alt="3D"
                    className={css(styles.backglyph)}
                    src={'./misc/LeftArrow.svg'}
                  />
                </div>
                <div className={css(styles.backContainer)}>Back</div>
              </h1> */}
            </div>
          </a.div>
        </>
      )}
    </>
  )
}

export const CylinderHud = () => {
  const pageState = usePage()
  return <CylinderOutside />
}

const Mouse = () => {
  const { page } = usePage()
  return (
    <>
      {/* <div>
          {
            <div className="hello">
              <p className={css(styles.mouseIcon)}>
                <MouseClick />
              </p>
              <p className={css(styles.clickHere)}>Click and drag or click the buttons to explore</p>
            </div>
          }
        </div> */}
    </>
  )
}
const styles = StyleSheet.create({
  categoryContainer: {
    width: '19.2vw',
    float: 'left',
    textAlign: 'center',
    // pointerEvents:'auto'
  },
  floatingCategoryContainer: {
    width: '19.2vw',
    position: 'absolute',
    bottom: '0.6vw',
    marginLeft: '2.1vw',
  },
  enterRoomButton: {
    // width: '19vw',
    pointerEvents: 'auto',
    cursor: 'pointer',
    ':hover': {
      opacity: '0.7',
    },
  },
  backContainer: {
    float: 'left',
  },
  backZindex: {
    zIndex: 11000,
  },
  backIconContainer: {
    float: 'left',
    // padding:1,
    boxSizing: 'border-box',
    // height:'2vh'
  },
  CTA: {
    marginTop: '4vh',
    width: '16vw',
    textAlign: 'center',
    fontSize: '1.5vw',
    lineHeight: '7vh',
    backgroundColor: colorThemes.textBlue,
  },
  container: {
    position: 'absolute',
    width: '26vw',
    height: '60%',
    top: '26vh',
    left: '3vw',
    bottom: '8%',
    zIndex: pageIdx.HUD,
    pointerEvents: 'none',
  },
  NavigationButton: {
    marginTop: '5vh',
  },
  hudButtonsEffects: {
    opacity: 1,
    pointerEvents: 'auto',
    cursor: 'pointer',
    ':hover': {
      transform: 'scale(1.1)',
    },
  },
  threedglyphContainer: {},
  threedglyph: {
    height: '1.3vw',
  },
  backglyph: {
    height: 24,
  },
  buttonContainer: {
    float: 'left',
    // paddingTop: 6,
  },
  threedtext: {
    float: 'left',
    marginLeft: '0.5vw',
    marginRight: '0.5vw',
  },
  button: {
    pointerEvents: 'auto',
  },
  scrollStatus: {
    position: 'absolute',
    right: '2%',
    bottom: '5%',
    width: '7.4%',
    // transform:'translate(-50%,-50%)'
  },
  mouseDiv: {
    position: 'absolute',
    bottom: '14vh',
    right: '50vh',
  },
  heading: {
    fontSize: 40,
    weight: 700,
    lineHeight: '46.88px',
    fontWeight: 700,
    color: colorThemes.text[0],
    fontFamily: 'Roboto',
    pointerEvents: 'auto',
    cursor: 'pointer',
  },

  roboto: {
    fontFamily: 'Roboto',
    fontWeight: 'lighter',
    // fontWeight:1
  },
  arrow: {
    /* Path 18 */

    marginLeft: '5%',
    marginTop: '100%',
    fontSize: '110%',
    fontFamily: 'Courier',
    fontWeight: 200,
  },
  navBar: {
    position: 'absolute',
    boxSizing: 'border-box',
    right: '3vw',
    top: '4.4vh',
    width: '32vw',
    // height:'10%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  roomAnimation: {
    position: 'absolute',
    height: '0.1vw',
    width: '10vw',
    backgroundColor: 'white',
    bottom: 0,
    margin: '2.1vw',
    marginBottom: '0.5vw',
  },
  bottomText: {
    width: '96vw',
    position: 'absolute',
    bottom: 0,
    margin: '2.1vw',
    marginBottom: '1.4vw',
    cursor: 'pointer',
    fontSize: '2vh',
    // pointerEvents:'auto'
  },
  clickHere: {
    fontWeight: 400,
    fontSize: '1.4vh',
    margin: 0,
    width: '30vw',
    textAlign: 'center',
    color: '#ffffff',
  },
  mouseIcon: {
    textAlign: 'center',
    margin: 0,
    paddingRight: '4.4vh',
  },
  button: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    margin: 20,
  },
  smallerFont: {
    fontFamily: 'Work Sans',
    fontWeight: 300,
    // fontSize: '2vh',
    // lineHeight: '2vh',
    // margin:0,
    // transform: 'translateY(28%)'
  },
})
