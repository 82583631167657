import { StyleSheet } from "aphrodite"
export const commonStyles = StyleSheet.create({
    paragraph:{
        fontFamily:'Helvetica',
        color:'#093145',

        // color:'lilia',
        fontSize:18,

    },
    heading:{
        fontFamily:'Helvetica',

        color:'#093145',
        fontSize:27
    }
})