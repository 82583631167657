import React, { Suspense, useEffect, useRef, useState } from 'react'
import { css, StyleSheet } from 'aphrodite'
import { baseUrl, useModal } from '../cmsstore'
import { commonStyles } from '../commonStyles'
import { Canvas, useThree } from '@react-three/fiber'
import { OrbitControls, useGLTF } from '@react-three/drei'
import Skybox from '../../components/core/skybox/Skybox'
import axios from 'axios'
import AR from '../../components/core/showcase/Ar'
import { CmsModelViewer } from './CmsModelViewer'
import DynamicSkybox from './DynamicSkybox'

export const Modal = (props) => {
  const modal = useModal()
  const [info, setInfo] = useState({})
  const ref = useRef()
  const [view,setView] = useState({})
//   console.log('tags',modal.data.tags,modal.data.tags.join(','),info.tags)
  const [actualGlb, setActualGlb] = useState()
  const [actualUsdz, setActualUsdz] = useState()
  const [loadingState, setLoading] = useState(0)
  const [actualImg, setImg] = useState()
  const [submit, setSubmit] = useState(true)
  const [fullscreen, setFullscreen] = useState(false)
  const handleGlbChange = (e) => {
    setInfo({ ...info, glb: e.target.value })
    setActualGlb(e.target.files[0])
  }
  const handleUsdzChange = (e) => {
    setInfo({ ...info, usdz: e.target.value })
    setActualUsdz(e.target.files[0])
  }
  const handleSubmitView = async (e) => {
    const formData = new FormData()
    formData.append('view',JSON.stringify(view))
    console.log(view)
    props.setLoading(1)
    try{
        let res = await axios.put(baseUrl+'/editView/'+modal.data._id,formData)
        props.setLoading(2)
        console.log('res',res)
    }catch(err){
        props.setLoading(-1)
        console.log('err',err)
    }
    props.toggleChanged()
  }
  const handleImgChange = (e) => {
    setInfo({ ...info, thumbnail: e.target.value })
    setImg(e.target.files[0])
  }
  const handleFullscreen = (shouldMaximize) => {
    if (shouldMaximize) {
      setFullscreen(true)
      // ref.current.domElement.requestFullscreen()
    } else setFullscreen(false)
  }
  useEffect(() => {
    // const div = document.createElement()
    // document.body.appendChild(div)
    // div.style={backgroundColor:"rgba(0,0,0,1)",position:"absolute"}
  }, [modal.show])
  useEffect(() => {
    // setInfo({})
    
    setInfo({
      features:'',
      collectionName:'',
      finish:'',
      tags:'',
      description:'',
      category:'',
      name:'',
      ...modal.data,
      glb: '',
      tags:modal.data.tags?.join(','),
      usdz: '',
      thumbnail: '',
      id: modal.data.productCode,
    })
    delete info.view
    modal.data.view?setView(modal.data.view):setView({})
    console.log("modal",modal.data)
  }, [modal.data])
  const handleDelete = async () => {
    try {
      let res = await axios.delete(
        baseUrl + `/?id=${modal.data._id}&modelId=${modal.data.productCode}`
      )
      // await axios.delete(baseUrl+'/deleteall')
      modal.setShow(false)
      props.toggleChanged()
      //console.log(res)
    } catch (err) {
      //console.log(err)
    }
    modal.setShow(false)
    props.toggleChanged()
  }
 
    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log('sending packet')
        props.setLoading(1)
        if(actualGlb)info["glb"] = actualGlb
        else delete info.glb
        if(actualUsdz)info.usdz = actualUsdz
        else delete info.usdz
        if(actualImg)info.thumbnail = actualImg
        else delete info.thumbnail
        let formData = new FormData()
        for (let i in info){
            if(i==='tags')console.log(i,info[i],info[i].split(/[\s,]+/))
            if(i==='thumbnail') formData.append(i,actualImg)
            else if(i==='tags') formData.append(i,info[i].split(/[\s,]+/))
            else formData.append(i,info[i])
        }
        
        info.glb=''
        info.usdz=''
        info.thumbnail=''
        // //console.log(formData)
        setLoading(1)
        try{
            let res = await axios.put(baseUrl+'/edit/'+modal.data._id,formData)
            props.setLoading(2)
            console.log('res',res)
        }catch(err){
            props.setLoading(-1)
            console.log('err',err)
        }
        // modal.setShow(false)
        props.toggleChanged()
    
    // modal.setShow(false)
    props.toggleChanged()
  }

  // const handleFileChange = (e) => {
  //     setInfo({...info,file:e.target.value})
  //     setActualFile(e.target.files[0])
  // }
  return (
    modal.show && (
      <>
        <div
          onClick={() => modal.setShow(false)}
          className={css(styles.backdrop)}
        ></div>
          <div className={css(styles.container)}>
            {/* <div className={css(styles.textContainer)}>
                    <h1 className={css(commonStyles.heading)}>{modal.data.name}</h1>
                    <p className={css(commonStyles.paragraph)}>{modal.data.createdAt}</p>
                    <p className={css(commonStyles.paragraph)}>{modal.data.description}</p>
                    <p className={css(commonStyles.paragraph)}>{modal.data.tags}</p>
                </div> */}
            <form
              className={css(styles.form, commonStyles.paragraph)}
              onSubmit={handleSubmit}
            >
              <label className={css(styles.label)}>Product Id</label>
              <input
                disabled={true}
                className={css(styles.input)}
                value={info.id}
                onChange={(e) => setInfo({ ...info, id: e.target.value })}
              />
              <label className={css(styles.label)}>Name</label>
              <input
                className={css(styles.input)}
                onChange={(e) => setInfo({ ...info, name: e.target.value })}
                value={info.name}
              />
              <label className={css(styles.label)}>Description</label>
              <textarea
                className={css(styles.input)}
                onChange={(e) =>
                  setInfo({ ...info, description: e.target.value })
                }
                value={info.description}
              />

              <label className={css(styles.label)}>
                Tags(comma separeted tags)
              </label>
              <input
                className={css(styles.input)}
                onChange={(e) => setInfo({ ...info, tags: e.target.value })}
                value={info.tags}
              />
              <label className={css(styles.label)}>Category (optional)</label>
              <input
                className={css(styles.input)}
                onChange={(e) => setInfo({ ...info, category: e.target.value })}
                value={info.category}
              />
              <label className={css(styles.label)}>Features (optional) Separete features using //</label>
              <textarea
                className={css(styles.input)}
                onChange={(e) => setInfo( {...info, features: e.target.value})}
                value={info.features}
              />
              <label className={css(styles.label)}>Collection Name (optional)</label>
              <input
                className={css(styles.input)}
                onChange={(e) => setInfo( {...info, collectionName: e.target.value})}
                value={info.collectionName}
              />
              <label className={css(styles.label)}>Finish (optional)</label>
              <input
                className={css(styles.input)}
                onChange={(e) => setInfo({ ...info, finish: e.target.value })}
                value={info.finish}
              />
              <label className={css(styles.label)}>File</label>
              <input
                type="file"
                className={css(styles.file)}
                value={info.glb}
                onChange={handleGlbChange}
              />
              <label className={css(styles.label)}>Usdz File</label>
              <input
                type="file"
                className={css(styles.file)}
                value={info.usdz}
                onChange={handleUsdzChange}
              />
              <label className={css(styles.label)}>Thumbnail File</label>
              <input
                type="file"
                className={css(styles.file)}
                value={info.thumbnail}
                onChange={handleImgChange}
              />
              <input
                disabled={!submit}
                value={'Edit'}
                className={css(styles.input)}
                type="submit"
              />
            </form>
            {/* {loadingState===1 && <p className={css(styles.label)} style={{color:'yellow'}}>Uploading....</p>}
                 {loadingState===2 && <p className={css(styles.label)} style={{color:'green'}}>Done....</p>} */}
                <button style={{position:'absolute',right:0,bottom:'10vh',zIndex:90}} onClick={handleDelete}>delete product</button>
                <div className={css(styles.model)}>
                    <AR appleUrl={baseUrl + `/usdz/${modal.data.productCode}/model.usdz`} androidURL={baseUrl + `/model/${modal.data.productCode}/model.glb`} />
                    
                            {/* <DynamicSkybox />
                        <Suspense fallback={null}>
                            <ModelViewer id={modal.data.productCode}/>
                        </Suspense>
                            <OrbitControls /> */}
                        <CmsModelViewer view={view} setView={setView} skyboxes={props.skyboxes} />
                    
                    <button onClick={()=>handleFullscreen(true)} className={css(styles.buttonFullscreen)}>Fullscreen</button>
                    <button onClick={handleSubmitView} className={css(styles.viewbutton)}>Set Camera Angle</button>
                </div>
            </div>
      </>
    )
  )
}

const styles = StyleSheet.create({
  backdrop: {
    position: 'absolute',
    zIndex: 5,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  container: {
    position: 'absolute',
    zIndex: 10,
    width: '100%',
    height: '70%',
    top: '50%',
    left: '50%',
    display: 'flex',
    // border:'1px solid black',
    backgroundColor: 'white',
    transform: 'translate(-50%,-50%)',
    '@media (max-width: 900px)': {
      width: '100%',
      left: 0,
      transform: 'translate(0,-50%)',
    },
  },
  textContainer: {
    // padding:'1%',
    width: '50%',
    height: '100%',
    overflow:'scroll'
  },
  model: {
    width: '95%',
    height: '85%',
    border: '1px white solid',
  },

  form: {
    display: 'block',
    width: '100%',
    margin: '1%',
    '@media (max-width: 900px)': {
      display: 'none',
    },
  },
  label: {
    display: 'block',
    fontSize: '0.7rem',
    marginTop: '1%',
  },
  input: {
    display: 'block',
    fontSize: '1rem',
    width: '90%',
  },
  fullscreen: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 30,
  },
  viewbutton:{
    position:'absolute',
    right:0,
    bottom:0
  },  
  buttonFullscreen: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
})
