import { Html } from '@react-three/drei'
import { useControls } from 'leva'
import React, { useEffect, useState } from 'react'
import {
  useCameraStore,
  useCylinder,
  usePage,
  usePopup,
  useProducts,
  useWorkaround,
} from '../../../../stores'
import { WorkAround } from '../../../Routing/WorkAround'
import { animated as a } from '@react-spring/web'
import { config, useSpring } from '@react-spring/core'
import { pulse, slideInLeft } from 'react-animations'
import { css, StyleSheet } from 'aphrodite'
import { textStylesV2 } from '../../../../appStyles'
import { isMobile } from 'react-device-detect'
import { sendEvent } from '../../../../google-analytics'

export function findProductCodeInArray(code, array) {
  for (var i in array) {
    if (array[i].productCode == code) return array[i]
  }
  return null
}

export const Hotspot = (props) => {
  const workaround = useWorkaround()
  const pageState = usePage()
  const cameraState = useCameraStore()
  const { products } = useProducts()
  const [productName, setProductName] = useState(null)
  const [hover, setHover] = useState(false)

  const popup = usePopup()
  const {
    fov,
    hotspot_position: { x, y, z },
    print_hotspot,
  } = useControls({
    fov: { value: 45, step: 1 },
    hotspot_position: { value: { x: 0, y: 0, z: 0 }, step: 0.025 },
    print_hotspot: { value: false },
  })
  function handleClick() {
    // workaround.setUrl(`/showcase`)
    //console.log('asdasd',props.id)
    sendEvent(`hotspot ${props.id} Clicked`, 'Hotspot Manager', 'Hotspot')
    workaround.setPopup(-1)
    workaround.setPopup(props.id)
  }
  useEffect(() => {
    print_hotspot && console.log(`position:[${x},${y},${z}]`)
  }, [print_hotspot])

  useEffect(() => {
    if (productName == null || productName == '') {
      let pd = findProductCodeInArray(props.productCode, products)
      if (pd != null) setProductName(pd.name)
      else setProductName('')
    }
  }, [props])
  const handleLog = () => {
    //console.log([x,y,z].map(Number))
  }
  const { opacity, opacity2 } = useSpring({
    from: { opacity: 0, opacity2: 0 },
    opacity:
      pageState.page === 'showcase' &&
      popup.data.popupId == null &&
      cameraState.tweening == false
        ? 1
        : 0,
    opacity2: hover ? 1 : 0,
    delay: 0,
  })

  const pulseAnimation = useSpring({
    from: {
      transform: 'scale(1)',
      opacity: 0.9,
    },
    to: [
      { transform: 'scale(0.7)', opacity: 0.7 },
      { transform: 'scale(1)', opacity: 0.9 },
    ],
    loop: true,
    config: config.molasses,
  })

  function handleHover(stat) {
    setHover(stat)
  }
  return (
    <>
      <group position={[0, 0, 0]}>
        <group position={props.position}>
          <Html
            zIndex={1}
            style={{
              position: 'absolute',
              zIndex: 1,
              height: '5vh',
              pointerEvents: 'none',
            }}
            zIndexRange={[3, 1]}
          >
            <a.div
              style={{
                opacity,
              }}
            >
              <a.div
                className={css(textStylesV2.HotspotR, textStylesV2.BasicColor)}
                style={{
                  opacity: opacity2,
                  position: 'absolute',
                  padding: '1.7vh',
                  marginLeft: props.hoverLeft ? '2vw' : '2vw',
                  transform: props.hoverLeft
                    ? 'translateX(-112%)'
                    : 'translateX(-0%)',
                  marginTop: '1vw',
                  width: 'max-content',
                  backgroundColor: '#4F4F4FEA',
                  // backgroundColor: 'blue',
                  pointerEvents: 'none',
                }}
              >
                {productName}
              </a.div>
              <img
                onPointerDown={handleClick}
                onPointerOver={() => handleHover(true)}
                onPointerOut={() => handleHover(false)}
                src="./misc/hotspot.png"
                alt={productName}
                className={css(!hover ? style.pulseAnimation : '')}
                style={{
                  marginTop: '6.5vh',
                  height: isMobile ? '5vh' : '3.5vh',
                  cursor: 'pointer',
                  pointerEvents: 'auto',
                }}
              />
            </a.div>
          </Html>
        </group>
      </group>
      {/* <group  position={[x,y,z]}>
                <Html style={{position:'absolute',zIndex:1}} zIndexRange={[3,1]}>
                    <div onPointerDown={handleLog} style={{backgroundColor:'black',width:'15px',height:'15px',cursor:'pointer',borderRadius:'100%'}}></div>                    
                </Html>
            </group> */}
    </>
  )
}

const opacityKeyframes = {
  from: {
    opacity: 1,
    transform: 'scale(0.9)',
  },

  to: { opacity: 0.5, transform: 'scale(0.8)' },
}

const style = StyleSheet.create({
  pulseAnimation: {
    animationName: [opacityKeyframes],
    animationDuration: '1s, 1s',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
  },
})

export const HotspotManger = (props) => {
  //console.log(props)

  return props.hotspots?.map((hotspot, i) => {
    return <>{<Hotspot key={i} {...hotspot} />}</>
  })
}
